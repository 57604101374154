import React, { useState } from "react";
// import "./ReportTable.css";
import { useEffect } from "react";
import axios from "axios";
import { parseInt } from "lodash";
import { useRef } from "react";
import { directory } from "../../utils/Label";
import { useReactToPrint } from "react-to-print";
import { right } from "glamor";
import HomeIcon from "@mui/icons-material/Home";
// import ReportTableLoop from "./ReportTableLoop";
import {
  FormControl,
  InputLabel,
  Link,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./ReportTableAr.css";
import { dateFormat } from "../../utils/Date";
import ComponentLoader from "../../ComponentLoader";

function ReportTableAr() {
  const [rolee, setRolee] = React.useState("");
  const [token, setToken] = useState("");
  const [Mname, setName] = useState("");

  const [invis, setInvestigator] = useState();
  const [insti, setInstitute] = useState("");
  const [ids, setIds] = useState();
  const [assM, setAssMonth] = useState("");
  const [dId, setDId] = useState();

  const [issTrue, setTrue] = useState("");

  const [ff, setFF] = useState({});

  const [state, setState] = useState();
  const Navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [userName, setUserName] = useState("");

  console.log(userName);

  const [report, setReport] = useState({
    masinvestigator: [],
    // trnIARA1A: [],
  });

  const homeIcon = () => {
    Navigate("/GeneralAR");
  };

  useEffect(() => {
    const rolee = localStorage.getItem("role");
    console.log(rolee);
    setRolee(rolee);
    const tok = localStorage.getItem("token");
    const investigator = localStorage.getItem("loginId");

    if (rolee === "Director") {
      const institudeNAme = localStorage.getItem("institudeNAme");
      setUserName(institudeNAme);
      const id=localStorage.getItem("ARIID")
      console.log(id,"check");
      setInstitute(id)
    } else {
      const username = localStorage.getItem("Name");
      setUserName(username);
      const institue = localStorage.getItem("loginId");
      setInstitute(institue);
  
    }
    setInvestigator(investigator);


    setIds(ids);
    setToken(tok);
    // setmydid()
    const DId = localStorage.getItem("getinfoDId");
    const obj = JSON.parse(DId);
    console.log(obj);
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    let reportTableData = localStorage.getItem("reportTableData");
    let reportData = JSON.parse(reportTableData);

    console.log(reportData.DirectorateId);
    setDId(reportData.DirectorateId);
    setFF(reportData);

    if (tok != null && tok != undefined) {
      if (rolee === "Investigator") {
        // inVistigator(tok, reportData, null, null);
      } else {
        handleChooseMonth(tok, reportData, null);
      }
    }
  }, []);
  console.log(invis, insti, assM);

  useEffect(() => {
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    console.log(myObject);
    if (myObject !== null) {
      setState(myObject);
    }
    let isTrue = localStorage.getItem("Mname");
    if (isTrue !== null) {
      setName(isTrue);
    }
    let issTrue = localStorage.getItem("role");
    setTrue(issTrue);
  }, []);

  const compoRef = useRef();

  const handleChooseMonth = async (token, reportData, obj) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    let d = {};

    if (obj !== null) {
      d = obj;
    } else {
      d = {
        RptMonth: parseInt(assM),
        DirectorateId: parseInt(dId),
        InstituteId: parseInt(insti),
      };
    }

    console.log(d);
    console.log(reportData);
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.REPORTVIEW}`,
        reportData,
        { headers: h }
      );
      console.log(data);
      setLoader(false);

      if (data) {
        setReport((prev) => ({
          ...prev,
          masinvestigator: data.masinvestigator,
          trnIARExcutiveSummary: data.trnIARExcutiveSummary[0],
          trnIARA1A: data.trnIARA1A[0],
          trnIARA1B: data.trnIARA1B,
          trnIARA1C: data.trnIARA1C,
          trnIARA1D: data.trnIARA1D,
          trnIARA1E: data.trnIARA1E,
          trnIARA1F: data.trnIARA1F,
          trnIARA2: data.trnIARA2,
          trnIARA3: data.trnIARA3,
          trnIARA4: data.trnIARA4,
          trnIARA5: data.trnIARA5,
          trnIARA6: data.trnIARA6,
          trnIARA7: data.trnIARA7,
          trnIARA11TSMS: data.trnIARA11TSMS,
          trnIARA8: data.trnIARA8,
          trnIARA9: data.trnIARA9,
          trnIARA10: data.trnIARA10,

          trnIARB1: data.trnIARB1,
          trnIARB2: data.trnIARB2,
          trnIARB06CourseHandled: data.trnIARB06CourseHandled,
          trnIARB07EffortTaken: data.trnIARB07EffortTaken,
          trnIARB08GuestLecture: data.trnIARB08GuestLecture,
          trnIARB09ExposureVisit: data.trnIARB09ExposureVisit,
          trnIARB10StudentGuidance: data.trnIARB10StudentGuidance,
          trnIARB11AdvisoryCommittee: data.trnIARB11AdvisoryCommittee,
          trnIARB12TeachingActivities: data.trnIARB12TeachingActivities,
          trnIARB3: data.trnIARB3,
          trnIARB4: data.trnIARB4,
          trnIARB5: data.trnIARB5,

          trnIARC01: data.trnIARC01,
          trnIARC02: data.trnIARC02,
          trnIARC03: data.trnIARC03,
          trnIARC04: data.trnIARC04,
          trnIARC05: data.trnIARC05,
          trnIARC06: data.trnIARC06,
          trnIARC07: data.trnIARC07,
          trnIARC08: data.trnIARC08,
          trnIARC09: data.trnIARC09,
          trnIARC10: data.trnIARC10,
          trnIARC11: data.trnIARC11,
          trnIARC12: data.trnIARC12,
          trnIARC13: data.trnIARC13,
          trnIARC14: data.trnIARC14,
          trnIARC15: data.trnIARC15,
          trnIARC16: data.trnIARC16,

          trnIARD01: data.trnIARD01,
          trnIARD02: data.trnIARD02,
          trnIARD03: data.trnIARD03,
          trnIARD04: data.trnIARD04,
          trnIARD05: data.trnIARD05,
          trnIARD06: data.trnIARD06,
          trnIARD07: data.trnIARD07,

          trnIARE02: data.trnIARE02,
          trnIARE01: data.trnIARE01,
          trnIARE03: data.trnIARE03,
          trnIARE04: data.trnIARE04,
          trnIARE05: data.trnIARE05,
          trnIARE06: data.trnIARE06,
          trnIARE07: data.trnIARE07,
          trnIARE08: data.trnIARE08,
          trnIARE09: data.trnIARE09,

          trnIARF01: data.trnIARF01,
          trnIARF02: data.trnIARF02,
          trnIARF03: data.trnIARF03,
          trnIARF04: data.trnIARF04,
          trnIARF05: data.trnIARF05,
          trnIARF06: data.trnIARF06,
          trnIARF07: data.trnIARF07,
        }));
        if (data && data.TrnMonthlyReport && data.TrnMonthlyReport.RptMonth) {
          localStorage.setItem("RptMonth", data.TrnMonthlyReport.RptMonth);
          localStorage.setItem("MRSId", data.TrnMonthlyReport.id);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  console.log(report.trnIARB12TeachingActivities);

  return (
    <>
      <div style={{ marginTop: 50 }}>
        <ComponentLoader isLoading={loader} />
        <div className="table-container-gri">
          <div className="tables-mains">
            <div
              className="table-head"
              style={{ position: "fixed", marginTop: "10px", width: "100%" }}
            >
              <div className="greenHead">
                <div className="head-icon">
                  <Link onClick={homeIcon}>
                    <HomeIcon style={{ color: "white", marginLeft: 38 }} />
                  </Link>
                </div>
              </div>
            </div>

            <div>
              <div className="containerss" style={{ paddingTop: "20px" }}>
                <div className="button-containerAR">
                  <div className="buton" style={{ position: "fixed" }}>
                    <button
                      style={{ border: "solid 1px #fff" }}
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
                <div>
                  <div
                    className="report-container"
                    ref={compoRef}

                    // style={{ width: "100%", height: window.innerHeight }}
                  >
                    <div
                      className="top-heading"
                      style={{ textAlign: "center", paddingTop: "20px" }}
                    >
                      <h3>TAMIL NADU AGRICULTURAL UNIVERSITY</h3>
                      {issTrue === "Investigator" ? (
                        <h4 className="h-6" style={{ fontSize: "16px" }}>
                          Individual Faculty/Staff
                        </h4>
                      ) : null}
                      <h4 className="h-6" style={{ fontSize: "13px" }}>
                        Monthly Report for the Month of {Mname}
                      </h4>
                    </div>

                    <h3>Executive Summary:</h3>
                    <div className="reportsummary">
                      <table
                        style={{ width: "100%", border: "1px solid black" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              I.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Highlights on Academic Activities
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {
                                report?.trnIARExcutiveSummary
                                  ?.academicActivities
                              }
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.academicActivities}</span>
                                  </div>
                                </> 
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              II.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Highlights on Research Activities
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {
                                report?.trnIARExcutiveSummary
                                  ?.researchActivities
                              }
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.researchActivities}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              III.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              colspan="5"
                              className="report-td"
                            >
                              {" "}
                              Highlights on Extension Activities
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              rowspan="3"
                              className="report-td"
                            >
                              {" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              No. of Training/Meeting/Exhibition organized (with
                              No. of participants)
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {report?.trnIARExcutiveSummary?.noOfTME}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.noOfTME}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              No. of diagnostic field visits made and solutions
                              provided
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {
                                report?.trnIARExcutiveSummary
                                  ?.extensionActivities
                              }
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.extensionActivities}</span>
                                  </div>
                                </>
                              );
                            })}  */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Radio Talk TV Programme Any other
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {report?.trnIARExcutiveSummary?.tvroProgram}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.tvroProgram}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              IV.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              No. of Externally funded projects obtained (with
                              budget)
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {report?.trnIARExcutiveSummary?.noOfProject}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.noOfProject}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              rowspan="3"
                              className="report-td"
                            >
                              V.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              rowspan="3"
                              className="report-td"
                            >
                              No. of Publications{" "}
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "40%",
                                border: "1px solid black",
                              }}
                              colspan="2"
                              className="report-td"
                            >
                              No. of Research Articles (&gt;6 NAAS Rating):{" "}
                            </td>
                            <td
                              style={{
                                width: "20%",
                                border: "1px solid black",
                              }}
                            >
                              {report?.trnIARExcutiveSummary?.researchArticle}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.researchArticle}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "40%",
                                border: "1px solid black",
                              }}
                              colspan="2"
                              className="report-td"
                            >
                              No. of Book Chapters (with ISBN):{" "}
                            </td>
                            <td
                              style={{
                                width: "20%",
                                border: "1px solid black",
                              }}
                            >
                              {report?.trnIARExcutiveSummary?.bookChapters}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.bookChapters}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "40%",
                                border: "1px solid black",
                              }}
                              colspan="2"
                              className="report-td"
                            >
                              No. of Books (with ISBN):
                            </td>
                            <td
                              style={{
                                width: "20%",
                                border: "1px solid black",
                              }}
                            >
                              {report?.trnIARExcutiveSummary?.books}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.books}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              VI.
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              {" "}
                              No. of Awards/Fellowships/ Honours obtained by the
                              faculty/centre
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {report?.trnIARExcutiveSummary?.noOfAward}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.noOfAward}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              VII.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Total income generated (Rs.){" "}
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {report?.trnIARExcutiveSummary?.totalIncome}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.totalIncome}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              VIII.{" "}
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              {" "}
                              Status of Audit Paras{" "}
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "20%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Total:
                              {report?.trnIARExcutiveSummary?.totalAudit}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>

                                  <span>{item.totalAudit}</span>

                                </>
                              );
                            })}  */}
                            </td>
                            <td
                              style={{
                                width: "20%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Dropped:
                              {report?.trnIARExcutiveSummary?.droppedAudit}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>

                                  <span>{item.droppedAudit}</span>

                                </>
                              );
                            })} */}
                            </td>
                            <td
                              style={{
                                width: "20%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              Pending:
                              {report?.trnIARExcutiveSummary?.pendingAudit}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>

                                  <span>{item.pendingAudit}</span>

                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                              className="report-td"
                            >
                              IX.
                            </td>
                            <td
                              style={{
                                width: "36%",
                                border: "1px solid black",
                              }}
                              className="report-td"
                            >
                              {" "}
                              Important Visitors{" "}
                            </td>
                            <td
                              style={{ width: "3%", border: "1px solid black" }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                width: "60%",
                                border: "1px solid black",
                              }}
                              colspan="3"
                            >
                              {report?.trnIARExcutiveSummary?.visitors}
                              {/* {report.trnIARExcutiveSummary?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.visitors}</span>
                                  </div>
                                </>
                              );
                            })}  */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h3> Administration</h3>
                    <div>
                      <table className="table tables" style={{ width: "100%" }}>
                        <tbody>
                          <tr style={{ width: "" }}>
                            <td style={{ width: "3%" }} className="report-td">
                              A
                            </td>
                            <td
                              scope="row"
                              className="th-row report-td"
                              style={{ width: "37%" }}
                            >
                              Name of the College / Department / Research
                              Station / KVK
                            </td>
                            <td style={{ width: "60%" }} className="th-row3">
                              {/* {report?.trnIARA1A?.instituteName} */}
                              {userName}
                              {/* {report.trnIARA1A?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.instituteName}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "3%" }} className="report-td">
                              B
                            </td>
                            <td
                              className="table-size report-td"
                              style={{ width: "37%" }}
                              // scope="row"
                            >
                              Name of the Reporting Officer
                            </td>
                            <td style={{ width: "60%" }} className="th-row3">
                              {report?.trnIARA1A?.reportingOfficer}
                              {/* {report.trnIARA1A?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.reportingOfficer}</span>
                                  </div>
                                </>
                              );
                            })} */}
                              {/* {report.trnIARA1A[0].reportingOfficer} */}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "3%" }} className="report-td">
                              C
                            </td>
                            <td
                              className="table-size report-td"
                              style={{ width: "37%" }}
                              // scope="row"
                            >
                              Mandate
                            </td>
                            {/* {report.trnIARA1A?.map((item) => (
                          <tr key={item.id}>
                            <td className="table-size" scope="row">
                              {item.reportingOfficer}
                            </td>
                            <td className="table-size">{item.mandate}</td>
                          </tr>
                        ))} */}
                            {/* <td className="table-size">{item.mandate}</td> */}
                            <td className="th-row3" style={{ width: "60%" }}>
                              {report?.trnIARA1A?.mandate}
                              {/* {report.trnIARA1A?.map((item) => {
                              return (
                                <>
                                  <div>
                                    <span>{item.mandate}</span>
                                  </div>
                                </>
                              );
                            })} */}
                            </td>
                          </tr>
                          {/* --------------------------------------------------------------------------------------------- */}
                          <tr>
                            <td style={{ width: "3%" }} className="report-td">
                              D
                            </td>
                            <td
                              className="table-size report-td"
                              style={{ width: "37%", fontWeight: "800" }}
                              scope="row"
                            >
                              Land Area (Total in acres)
                            </td>
                            <td
                              className="th-row3"
                              style={{ width: "60%" }}
                            ></td>
                          </tr>
                          <tr>
                            <td style={{ width: "3%" }}></td>
                            <td className="th-row3" style={{ width: "37%" }}>
                              {report.trnIARA1B?.map((item) => {
                                return (
                                  <>
                                    <div>
                                      <span>{item.landAreaType}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </td>
                            <td className="th-row3" style={{ width: "60%" }}>
                              {report.trnIARA1B?.map((item) => {
                                return (
                                  <>
                                    <div>
                                      <span>{item.landAreaValue}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                          {/* --------------------------------------------------------------------------------------------- */}
                          <tr>
                            <td style={{ width: "3%" }} className="report-td">
                              E
                            </td>
                            <td
                              className="table-size report-td"
                              style={{ width: "37%", fontWeight: "800" }}
                              // scope="row"
                            >
                              Water Resources
                            </td>
                            <td
                              className="th-row3"
                              style={{ width: "60%" }}
                            ></td>
                          </tr>
                          <tr>
                            <td style={{ width: "3%" }}></td>
                            <td className="th-row3" style={{ width: "37%" }}>
                              {report.trnIARA1C?.map((item) => {
                                return (
                                  <>
                                    <div>
                                      <span>{item.waterResourceType}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </td>
                            <td className="th-row3" style={{ width: "60%" }}>
                              {report.trnIARA1C?.map((item) => {
                                return (
                                  <>
                                    <div>
                                      <span>{item.waterResourceValue}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                          {/* --------------------------------------------------------------------------------------------- */}
                          <tr>
                            <td style={{ width: "3%" }} className="report-td">
                              {" "}
                              G
                            </td>
                            <td
                              className="table-size report-td"
                              style={{ width: "37%", fontWeight: "800" }}
                              // scope="row"
                            >
                              Live Stock
                            </td>
                            <td className="th-row3" style={{ width: "60%" }}>
                              {" "}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "3%" }}></td>
                            <td className="th-row3" style={{ width: "37%" }}>
                              {report.trnIARA1E?.map((item) => {
                                return (
                                  <>
                                    <div>
                                      <span>{item.livestockType}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </td>
                            <td className="th-row3" style={{ width: "60%" }}>
                              {report.trnIARA1E?.map((item) => {
                                return (
                                  <>
                                    <div>
                                      <span>{item.livestockValue}</span>
                                    </div>
                                  </>
                                );
                              })}
                            </td>
                          </tr>
                          {/* ------------------------------------------------------------------------------------ */}
                          {/* <tr>
                          <td style={{ width: "5%" }}>F</td>
                          <td
                            className="table-size"
                            style={{ width: "20px" }}
                            scope="row"
                          >
                            Crop(s) in the field
                          </td>
                          <td className="th-row3"></td>
                        </tr>

                        <tr>
                          <td style={{ width: "5%" }}>G</td>
                          <td
                            className="table-size"
                            style={{ width: "20px" }}
                            scope="row"
                          >
                            Livestock details:
                          </td>
                          <td className="th-row3"></td>
                        </tr> */}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <table className="table tables">
                        <tbody>
                          <tr>
                            <td style={{ width: "1%" }} className="report-td">
                              F
                            </td>
                            <td
                              scope="row"
                              className="th-row report-td"
                              style={{ width: "40%", fontWeight: "800" }}
                            >
                              A1(F). Crop(s) in the field
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <div>
                                <table class="table table-2">
                                  <thead style={{ border: " 1px solid" }}>
                                    <tr>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Crop{" "}
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Variety
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Area (ac.)
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Purpose
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {report.trnIARA1D?.map((item) => (
                                      <tr key={item.id}>
                                        <td className="table-size" scope="row">
                                          {item.cropName}
                                        </td>
                                        <td className="table-size">
                                          {item.variety}
                                        </td>
                                        <td className="table-size">
                                          {item.area}
                                        </td>
                                        <td className="table-size">
                                          {item.purpose}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ width: "1%" }} className="report-td">
                              H
                            </td>
                            <td
                              scope="row"
                              className="th-row report-td"
                              style={{ width: "40%", fontWeight: "800" }}
                            >
                              A1(H). Building Details (including the quarters)
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <div className="a2-tables">
                                <table class="table table">
                                  <thead style={{ border: "1px solid" }}>
                                    <tr>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Survey No.
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Building Details
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Area(Square Feet)
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Budget (in lakh)
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        Year of construction
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        In Use/ Not in use
                                      </td>
                                      <td
                                        className="table-size report-td"
                                        scope="col"
                                      >
                                        If not in use, reason there of
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {report.trnIARA1F?.map((item) => (
                                      <tr key={item.id}>
                                        <td className="table-size">
                                          {item.surveyNo}
                                        </td>
                                        <td className="table-size">
                                          {item.buildingDetail}
                                        </td>
                                        <td className="table-size">
                                          {item.area}
                                        </td>
                                        <td className="table-size">
                                          {item.budget}
                                        </td>
                                        <td className="table-size">
                                          {item.year}
                                        </td>
                                        <td className="table-size">
                                          {item.issues}
                                        </td>
                                        <td className="table-size">
                                          {item.reason}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="a3-tables">
                      <h6 className="h-bold">A2. Staff strength:</h6>
                      <table class="table table" width={100}>
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={100}
                              style={{width : '11%'}}
                            >
                              Post Group
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={20}
                              style={{width : '11%'}}
                            >
                              Post
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={10}
                            >
                              Sanctioned Main Post
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={10}
                            >
                              Sanctioned Scheme Post
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={10}
                            >
                              Position Main Post
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={10}
                            >
                              position Scheme Post
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={10}
                            >
                              Vacancy Main Post
                            </td>
                            <td
                              className="table-size report-td"
                              scope="col"
                              width={10}
                            >
                              Vacancy Scheme Post
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA2?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              {/* <TableCell >{row.postGroup}</TableCell>
                  <TableCell >{row.postGroup}</TableCell> */}
                              <td className="table-size">{item.postGroup}</td>
                              <td className="table-size">{item.post}</td>
                              <td className="table-size">
                                {item.sanctionedPostMain}
                              </td>
                              <td className="table-size">
                                {item.sanctionedPostScheme}
                              </td>
                              <td className="table-size">
                                {item.positionMain}
                              </td>
                              <td className="table-size">
                                {item.positionScheme}
                              </td>
                              <td className="table-size">{item.vacanyMain}</td>
                              <td className="table-size">
                                {item.vacanyScheme}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a4-tables">
                      <h6 className="h-bold">
                        A3. List of Permanent Staff members:
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No.</td> */}
                            <td className="table-size report-td" scope="col">
                              Name of the Staff Member
                            </td>
                            <td className="table-size report-td" scope="col">
                              Designation
                            </td>
                            <td className="table-size report-td" scope="col">
                              Main / Scheme in which working
                            </td>
                            <td className="table-size report-td" scope="col">
                              Date of joining in the present office
                            </td>
                            <td className="table-size report-td" scope="col">
                              Assigned Responsibiities
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA3?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.designation}</td>
                              <td className="table-size">{item.workingWith}</td>
                              <td className="table-size">
                                {dateFormat(item.dateOfJoin)}
                              </td>
                              <td className="table-size">
                                {item.responsibilites}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a5-tables">
                      <h6 className="h-bold">
                        A4. Leave particulars of the Staff Members:
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No.</td> */}
                            <td className="table-size report-td" scope="col">
                              Name & Designation
                            </td>
                            <td className="table-size report-td" scope="col">
                              UPF /CPF No.
                            </td>
                            <td className="table-size report-td" scope="col">
                              Leave Details (No. of days)-CL
                            </td>
                            <td className="table-size report-td" scope="col">
                              CF
                            </td>
                            <td className="table-size report-td" scope="col">
                              RH
                            </td>
                            <td className="table-size report-td" scope="col">
                              OD
                            </td>
                            <td className="table-size report-td" scope="col">
                              EL/ML
                            </td>
                            <td className="table-size report-td" scope="col">
                              Date(s)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Reason for Leave
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA4?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">{item.designation}</td>
                              <td className="table-size">{item.upfcpfNo}</td>
                              <td className="table-size">{item.clDays}</td>
                              <td className="table-size">{item.cplDays}</td>
                              <td className="table-size">{item.rhDays}</td>
                              <td className="table-size">{item.odDays}</td>
                              <td className="table-size">{item.elmlDays}</td>
                              <td className="table-size">{item.dates}</td>
                              <td className="table-size">{item.reason}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a6-tables">
                      <h6 className="h-bold">
                        A5. Tour / on-duty particulars:
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No.</td> */}
                            <td className="table-size report-td" scope="col">
                              Name of the staff
                            </td>
                            <td className="table-size report-td" scope="col">
                              Tour / on-duty
                            </td>
                            <td className="table-size report-td" scope="col">
                              From Date
                            </td>
                            <td className="table-size report-td" scope="col">
                              To Date
                            </td>
                            <td className="table-size report-td" scope="col">
                              Place visited
                            </td>
                            <td className="table-size report-td" scope="col">
                              Purpose
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA5?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.tourOrDutyName}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.fromDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.toDate)}
                              </td>
                              <td className="table-size">
                                {item.placeOfVisit}
                              </td>
                              <td className="table-size">{item.purpose}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a7-tables">
                      <h6 className="h-bold">A6. Audit:</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size report-td" scope="col">
                              No. of audit objections pending at the beginning
                              of the month (A)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Replies sent during this month
                            </td>
                            <td className="table-size report-td" scope="col">
                              No. of audit objections dropped during the month
                              (B)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Pending audit objections (A-B)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA6?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.noOfAudit}</td>
                              <td className="table-size">
                                {item.replyDetails}
                              </td>
                              <td className="table-size">
                                {item.auditDropped}
                              </td>
                              <td className="table-size">
                                {item.pendingAudit}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        A7. Details on TSMS non-submission
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size report-td" scope="col">
                              Name
                            </td>
                            <td className="table-size report-td" scope="col">
                              Designation
                            </td>
                            <td className="table-size report-td" scope="col">
                              Date of non-submission
                            </td>
                            <td className="table-size report-td" scope="col">
                              Reason
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA11TSMS?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.designation}</td>
                              <td className="table-size">
                                {dateFormat(item.date)}
                              </td>
                              <td className="table-size">{item.reason}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        A8. Budget & Expenditure Details (in Lakhs)
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size report-td" scope="col">
                              Scheme
                            </td>
                            <td className="table-size report-td" scope="col">
                              Head
                            </td>
                            <td className="table-size report-td" scope="col">
                              Sanctioned Budget (Rs){" "}
                            </td>
                            <td className="table-size report-td" scope="col">
                              Budget received (Rs){" "}
                            </td>
                            <td className="table-size report-td" scope="col">
                              Budget available at the beginning of the month
                              (Rs.)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Expenditure during this month (Rs.)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Balance (Rs.)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA7?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.schemeName}</td>
                              <td className="table-size">{item.acHead}</td>
                              <td className="table-size">
                                {item.sanctionedBudget}
                              </td>
                              <td className="table-size">
                                {item.budgetReceived}
                              </td>
                              <td className="table-size">{item.availAmount}</td>
                              <td className="table-size">{item.expAmount}</td>
                              <td className="table-size">{item.balance}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <h6 className="h-6">B. Research Activities</h6> */}
                    {/* <h6 className="h-bold">B1. Research Area:</h6> */}
                    {/* <h6 className="h-bold">B2. Crop:</h6> */}

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        A9. Details of vehicles in the College / Station /
                        Department / KVK
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size report-td" scope="col">
                              Name of the Station/ KVK/College/Department
                            </td>
                            <td className="table-size report-td" scope="col">
                              Name of the Vehicle* (with Model name)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Year of Purchase
                            </td>
                            <td className="table-size report-td" scope="col">
                              Fuel consumption during this month (L)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Expenditure incurred during this month (Rs.)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA8?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.vechileModel}
                              </td>
                              <td className="table-size">
                                {item.purchaseYear}
                              </td>
                              <td className="table-size">
                                {item.fuelConsumption}
                              </td>
                              <td className="table-size">{item.expenses}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        A10. Details on the Legal / Disciplinary cases
                      </h6>
                      <table class="table table ">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size report-td" scope="col">
                              Issue
                            </td>
                            <td className="table-size report-td" scope="col">
                              Staff involved
                            </td>
                            <td className="table-size report-td" scope="col">
                              Legal or Disciplinary (L/D)
                            </td>
                            <td className="table-size report-td" scope="col">
                              Date of origin
                            </td>
                            <td className="table-size report-td" scope="col">
                              Status
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA9?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.issues}</td>
                              <td className="table-size">
                                {item.staffInvolved}
                              </td>
                              <td className="table-size">
                                {item.legalDetails}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.dateOfOrigin)}
                              </td>
                              <td className="table-size">{item.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        A11. Monthly meeting(s) organized during this month
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size report-td" scope="col">
                              Date of monthly meeting
                            </td>
                            <td className="table-size report-td" scope="col">
                              Major agenda
                            </td>
                            <td className="table-size report-td" scope="col">
                              Remarks
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARA10?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {dateFormat(item.meetingDate)}
                              </td>
                              <td className="table-size">
                                {item.meetingAgenda}
                              </td>
                              <td className="table-size">{item.remarks}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <h3> Educational Activities</h3>
                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B1. Degree Program & Students strength
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the College / Departments / Research
                              Station
                            </td>
                            <td className="table-size" scope="col">
                              Male students
                            </td>
                            <td className="table-size" scope="col">
                              Female students
                            </td>
                            <td className="table-size" scope="col">
                              Total number of students
                            </td>
                            <td className="table-size" scope="col">
                              State Govt. sponsored fellowship(s)
                            </td>
                            <td className="table-size" scope="col">
                              Central Govt. sponsored fellowship(s)
                            </td>
                            <td className="table-size" scope="col">
                              Govt. competitive fellowship(s)
                            </td>
                            <td className="table-size" scope="col">
                              Private fellow- ship(s)
                            </td>
                            <td className="table-size" scope="col">
                              Total
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB1?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.noOfMaleStudents}
                              </td>
                              <td className="table-size">
                                {item.noOfFemaleStudents}
                              </td>
                              <td className="table-size">
                                {item.totalNoStudents}
                              </td>
                              <td className="table-size">
                                {item.stateGovtFellowship}
                              </td>
                              <td className="table-size">
                                {item.centralGovtFellowship}
                              </td>
                              <td className="table-size">
                                {item.govtCompetitiveFellowship}
                              </td>
                              <td className="table-size">
                                {item.privateFellowship}
                              </td>
                              <td className="table-size">
                                {item.totalFellowship}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B2. Diploma / UG / PG / Ph.D. Courses handled
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Course Number
                            </td>
                            <td className="table-size" scope="col">
                              Credit hours
                            </td>
                            <td className="table-size" scope="col">
                              Dip / UG / PG / Ph.D.
                            </td>
                            <td className="table-size" scope="col">
                              Course Teacher / Associate
                            </td>
                            <td className="table-size" scope="col">
                              No. of classes conducted (Theory + Practical)
                            </td>
                            <td className="table-size" scope="col">
                              Study materials provided to the students (Yes /No)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB06CourseHandled?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.courseNumber}
                              </td>
                              <td className="table-size">{item.hours}</td>
                              <td className="table-size">{item.courseType}</td>
                              <td className="table-size">{item.courseRole}</td>
                              <td className="table-size">{item.noOfClassTP}</td>
                              <td className="table-size">
                                {item.studyMaterialsProvided}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B3. Additional efforts taken in teaching
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Course Number
                            </td>
                            <td className="table-size" scope="col">
                              Credit hours
                            </td>
                            <td className="table-size" scope="col">
                              No. of slow learners identified
                            </td>
                            <td className="table-size" scope="col">
                              Weaknesses identified
                            </td>
                            <td className="table-size" scope="col">
                              Additional efforts taken to the slow learners
                            </td>
                            <td className="table-size" scope="col">
                              Pass %
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB07EffortTaken?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.courseNumber}
                              </td>
                              <td className="table-size">{item.hours}</td>
                              <td className="table-size">
                                {item.noofslowlearners}
                              </td>
                              <td className="table-size">
                                {item.noofweakness}
                              </td>
                              <td className="table-size">
                                {item.additionalefforts}
                              </td>
                              <td className="table-size">{item.pass}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">B4. Guest Lectures organized</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Course Number
                            </td>
                            <td className="table-size" scope="col">
                              Credit hours
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Guest Speaker
                            </td>
                            <td className="table-size" scope="col">
                              Affiliation
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              Title of the lecture
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB08GuestLecture?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.courseNumber}
                              </td>
                              <td className="table-size">{item.hours}</td>
                              <td className="table-size">
                                {item.guestSpeaker}
                              </td>
                              <td className="table-size">{item.affiliation}</td>
                              <td className="table-size">
                                {dateFormat(item.guestLectureDate)}
                              </td>
                              <td className="table-size">
                                {item.titleofthelecture}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">B5. Exposure visits arranged</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Course Number
                            </td>
                            <td className="table-size" scope="col">
                              Credit hours
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Institute / Industry visited
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              No. of students
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB09ExposureVisit?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.courseNumber}
                              </td>
                              <td className="table-size">{item.hours}</td>
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.exposureVisitsDate)}
                              </td>
                              <td className="table-size">
                                {item.noOfStudents}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">B6. Student Guidance</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col" style={{width : '11%'}}>
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col" style={{width : '13%'}}>
                              Name of the Student
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              ID No.
                            </td>
                            <td className="table-size" scope="col">
                              UG/PG / Ph.D.
                            </td>
                            <td className="table-size" scope="col">
                              Project Guide / Chairman / Member
                            </td>
                            <td className="table-size" scope="col" style={{width : '28%'}}>
                              Project Title/ Thesis title
                            </td>
                            <td className="table-size" scope="col">
                              Fellowship provided
                            </td>
                            <td className="table-size" scope="col">
                              Date of Thesis submission
                            </td>
                            <td className="table-size" scope="col">
                              Date of Final viva voce
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB10StudentGuidance?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.studentName}</td>
                              <td className="table-size">{item.studentId}</td>
                              <td className="table-size">{item.courseType}</td>
                              <td className="table-size">{item.memberRole}</td>
                              <td className="table-size">{item.thesistitle}</td>
                              <td className="table-size">
                                {item.fellowshipdetails}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.thesissubmission)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.finalVVDate)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B7. Details of Advisory Committee Meetings
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col" style={{width : '11%'}}>
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col" style={{width : '13%'}}>
                              Name of the Student
                            </td>
                            <td className="table-size" scope="col">
                              No. of meetings held during the month
                            </td>
                            <td className="table-size" scope="col">
                              Institute identified for carrying out part of the
                              thesis research
                            </td>
                            <td className="table-size" scope="col">
                              Plan for the internship
                            </td>
                            <td className="table-size" scope="col">
                              Progress
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB11AdvisoryCommittee?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.studentName}</td>
                              <td className="table-size">
                                {item.noofmeetings}
                              </td>
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.internshipPlan}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B8. Teaching related activities
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Nature of the job
                            </td>
                            <td className="table-size" scope="col">
                              Details Of Work
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB12TeachingActivities?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.natureofthejob}
                              </td>
                              <td className="table-size">
                                {item.detailsOfWork}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">B9. Student facilitation</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Designation
                            </td>
                            <td className="table-size" scope="col">
                              Nature of coaching offered
                            </td>
                            <td className="table-size" scope="col">
                              No. of classes offered
                            </td>
                            <td className="table-size" scope="col">
                              Name of the fellowship
                            </td>
                            <td className="table-size" scope="col">
                              Number of students
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB2?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.designation}</td>
                              <td className="table-size">
                                {item.natureofcoaching}
                              </td>
                              <td className="table-size">
                                {item.noofclassesoffered}
                              </td>
                              <td className="table-size">
                                {item.fellowshipName}
                              </td>
                              <td className="table-size">{item.noOfStudent}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">B10. Student Placement details</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Degree Programme
                            </td>
                            <td className="table-size" scope="col">
                              Name of the student
                            </td>
                            <td className="table-size" scope="col">
                              Student Id
                            </td>
                            <td className="table-size" scope="col">
                              Year/ Batch
                            </td>
                            <td className="table-size" scope="col">
                              Student’s placement at
                            </td>
                            <td className="table-size" scope="col">
                              Salary (p.m.)
                            </td>
                            <td className="table-size" scope="col">
                              Placement through
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB3?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.departmentName}
                              </td>
                              <td className="table-size">
                                {item.degreeProgramme}
                              </td>
                              <td className="table-size">{item.studentName}</td>
                              <td className="table-size">{item.studentId}</td>
                              <td className="table-size">{item.year}</td>
                              <td className="table-size">
                                {item.stuPlacement}
                              </td>
                              <td className="table-size">{item.salary}</td>
                              <td className="table-size">{item.placement}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B11. Students’ Achievements in Academics
                        (Fellowships/Awards/Rank only)
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Degree Programme
                            </td>
                            <td className="table-size" scope="col">
                              Name of the student and ID No.
                            </td>
                            <td className="table-size" scope="col">
                              Student ID No.
                            </td>
                            <td className="table-size" scope="col">
                              Year / Batch
                            </td>
                            <td className="table-size" scope="col">
                              Particulars
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB4?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.departmentName}
                              </td>
                              <td className="table-size">
                                {item.degreeProgramme}
                              </td>
                              <td className="table-size">{item.studentName}</td>
                              <td className="table-size">{item.studentId}</td>
                              <td className="table-size">{item.year}</td>
                              <td className="table-size">{item.particulars}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        B12. Students’ Achievements in Extra-curricular
                        activities (Prizes won at Intercollegiate/State/National
                        level)
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Degree Programme
                            </td>
                            <td className="table-size" scope="col">
                              Name of the student and ID No.
                            </td>
                            <td className="table-size" scope="col">
                              Student ID No.
                            </td>
                            <td className="table-size" scope="col">
                              Year / Batch
                            </td>
                            <td className="table-size" scope="col">
                              Particulars
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARB5?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.departmentName}
                              </td>
                              <td className="table-size">
                                {item.degreeProgramme}
                              </td>
                              <td className="table-size">{item.studentName}</td>
                              <td className="table-size">{item.studentId}</td>
                              <td className="table-size">{item.year}</td>
                              <td className="table-size">{item.particulars}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <h3>Research</h3>
                    <div className="a8-tables">
                      <h6 className="h-bold">C1. Crop varieties / hybrids</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">s.No.</td> */}
                            <td className="table-size" scope="col">
                              Name of Crop /Variety
                            </td>
                            <td className="table-size" scope="col">
                              Scientists involved
                            </td>
                            <td className="table-size" scope="col">
                              Special features of the product
                            </td>

                            <td className="table-size" scope="col">
                              Current Status
                            </td>
                            <td className="table-size" scope="col">
                              Tentative time line for release
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC01?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.cropName}</td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.specialFuture}
                              </td>
                              <td className="table-size">{item.status}</td>
                              <td className="table-size">
                                {item.tentativeTimeLine}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">C2. Technologies </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of Crop technology
                            </td>
                            <td className="table-size" scope="col">
                              Scientists involved
                            </td>
                            <td className="table-size" scope="col">
                              Special features of the product
                            </td>

                            <td className="table-size" scope="col">
                              Current Status
                            </td>
                            <td className="table-size" scope="col">
                              Tentative time line for release
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC02?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.technologyName}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.specialFuture}
                              </td>
                              <td className="table-size">{item.status}</td>
                              <td className="table-size">
                                {item.tentativeTimeLine}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C3. Farm Implements / Tools / Software
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of Item
                            </td>
                            <td className="table-size" scope="col">
                              Scientists involved
                            </td>
                            <td className="table-size" scope="col">
                              Special features of the product
                            </td>

                            <td className="table-size" scope="col">
                              Current Status
                            </td>
                            <td className="table-size" scope="col">
                              Tentative time line for release
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC03?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.itemName}</td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.specialFuture}
                              </td>
                              <td className="table-size">{item.status}</td>
                              <td className="table-size">
                                {" "}
                                {item.tentativeTimeLine}
                              </td>
                              {/* <td className="table-size">{item.settlementofaccounts}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C4. University Research Project
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Project No.
                            </td>
                            <td className="table-size" scope="col">
                              Title of the project
                            </td>
                            <td className="table-size" scope="col">
                              Project Leader(s)
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              Start date
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              End date
                            </td>
                            <td className="table-size" scope="col">
                              Progress during this Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC04?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}

                              <td className="table-size">{item.projectNo}</td>
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.projectLeader}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.startDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.endDate)}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">C5. AICRP Project</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Title of the project
                            </td>
                            <td className="table-size" scope="col" style={{width : '14%'}}>
                              Name of the scientist(s)
                            </td>
                            <td className="table-size" scope="col">
                              Progress during this Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC05?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">C6. Venture Capital Projects</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Project No.
                            </td>
                            <td className="table-size" scope="col">
                              Title of the project (with Budget)
                            </td>
                            <td className="table-size" scope="col">
                              Name of the scientist(s)
                            </td>
                            <td className="table-size" scope="col">
                              Budget (Rs.)
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              Start date
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              End date
                            </td>
                            <td className="table-size" scope="col">
                              Progress during this Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC06?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.projectNo}</td>
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                              <td className="table-size">
                                {dateFormat(item.startDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.endDate)}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">C7. Revolving Fund Projects</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Project No.
                            </td>
                            <td className="table-size" scope="col">
                              Title of the project
                            </td>
                            <td className="table-size" scope="col">
                              Name of the scientist(s)
                            </td>
                            <td className="table-size" scope="col">
                              Budget (Rs.)
                            </td>
                            <td className="table-size" scope="col">
                              Start date
                            </td>
                            <td className="table-size" scope="col">
                              End date
                            </td>
                            <td className="table-size" scope="col">
                              Progress during this Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC07?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">{item.projectNo}</td>
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                              <td className="table-size">
                                {dateFormat(item.startDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.endDate)}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">C8. Externally funded Projects</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Project No
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Project
                            </td>
                            <td className="table-size" scope="col">
                              Name of the PIs/CoPIs
                            </td>
                            <td className="table-size" scope="col">
                              Funding Agency
                            </td>
                            <td className="table-size" scope="col">
                              Budget (Rs.)
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              Start date
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              End date
                            </td>
                            <td className="table-size" scope="col" style={{width : '20%'}}>
                              Progress during this Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC08?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.projectNo}</td>
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.investigatorType}
                              </td>
                              <td className="table-size">
                                {item.fundingAgencyName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                              <td className="table-size">
                                {dateFormat(item.startDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.endDate)}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C9. Bio-efficacy / Product Testing Projects
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the Project
                            </td>
                            <td className="table-size" scope="col">
                              Funding Agency
                            </td>
                            <td className="table-size" scope="col">
                              Name of the PIs
                            </td>
                            <td className="table-size" scope="col">
                              Budget (Rs.)
                            </td>
                            <td className="table-size" scope="col">
                              Start date
                            </td>
                            <td className="table-size" scope="col">
                              End date
                            </td>
                            <td className="table-size" scope="col">
                              Progress during this Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC09?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.projectName}</td>
                              <td className="table-size">
                                {item.fundingAgencyName}
                              </td>
                              <td className="table-size">{item.pIsName}</td>
                              <td className="table-size">{item.budget}</td>
                              <td className="table-size">
                                {dateFormat(item.startDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.endDate)}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">C10. Germplasm collection</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the Station/Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Scientist(s) involved
                            </td>
                            <td className="table-size" scope="col">
                              Crop
                            </td>
                            <td className="table-size" scope="col">
                              Germplasm collected during this month
                            </td>
                            <td className="table-size" scope="col">
                              Total Germplasm collected so far
                            </td>
                            <td className="table-size" scope="col" style={{width : '26%'}}>
                              Special features
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC10?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.cropName}</td>
                              <td className="table-size">{item.duringMonth}</td>
                              <td className="table-size">
                                {item.totalGermplasmcollected}
                              </td>
                              <td className="table-size">
                                {item.prodcutSpecialFeature}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <h6 className="h-6">C. Extension Activities</h6>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C11. Patents / GIs / NBPGR / PPVFRA registration
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the Station/Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Scientist(s) involved
                            </td>
                            <td className="table-size" scope="col">
                              Description of the product
                            </td>
                            <td className="table-size" scope="col">
                              Patent/GI /NBPGR / PPVFRA detai
                            </td>
                            <td className="table-size" scope="col" style={{width : '36%'}}>
                              Remarks
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC11?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.prodcutDescription}
                              </td>
                              <td className="table-size">{item.patentType}</td>
                              <td className="table-size">{item.remakrs}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C12. Academic / Industrial linkages established
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the Station/Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Scientist(s) in- charge
                            </td>
                            {/* <td className="table-size" scope="col">
                    No. of days
                  </td> */}
                            <td className="table-size" scope="col">
                              Name of the Organization
                            </td>
                            <td className="table-size" scope="col">
                              National / International/ Private
                            </td>
                            <td className="table-size" scope="col">
                              Date of signing of MoU/MTA
                            </td>
                            <td className="table-size" scope="col">
                              Purpose of the linkage established
                            </td>
                            <td className="table-size" scope="col">
                              Fellow up activitie
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC12?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.organizationName}
                              </td>
                              <td className="table-size">
                                {item.linkagesType}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.dateOfMOU)}
                              </td>
                              <td className="table-size">{item.purpose}</td>
                              <td className="table-size">{item.followup}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C13. MLT/OFT/Action Plan – On going
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr width={100}>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col" width={40}>
                              MLT / OFT / Action Plan
                            </td>
                            {/* <td className="table-size" scope="col">
                    Variety/Technology Demonstrated
                  </td> */}

                            <td className="table-size" scope="col" width={5}>
                              Category
                            </td>
                            <td className="table-size" scope="col" width={5}>
                              Scientist(s) in-charge
                            </td>
                            <td className="table-size" scope="col" width={50}>
                              Progress during the Month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC13?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">{item.actionPlan}</td>
                              <td className="table-size">{item.category}</td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.progress}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">C14. New Project(s) Proposed</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col" style={{width : '13%'}}>
                              Faculty Name
                            </td>
                            <td className="table-size" scope="col">
                              Title of the Project
                            </td>
                            <td className="table-size" scope="col">
                              Funding Agency
                            </td>
                            <td className="table-size" scope="col">
                              Budget in Lakhs
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              Date of Submission
                            </td>
                            <td className="table-size" scope="col" style={{width : '11%'}}>
                              Status
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC14?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}

                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.fundingAgencyName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                              <td className="table-size">
                                {dateFormat(item.submissionDate)}
                              </td>
                              <td className="table-size">{item.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">
                        C15. Closure of completed projects
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col" style={{width : '11%'}}>
                              Faculty Name
                            </td>
                            <td className="table-size" scope="col">
                              Title of the Project
                            </td>
                            <td className="table-size" scope="col">
                              Funding Agency
                            </td>
                            <td className="table-size" scope="col">
                              Date of closure
                            </td>
                            <td className="table-size" scope="col">
                              Submission of completion report
                            </td>
                            <td className="table-size" scope="col">
                              Settlement of accounts
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC15?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.projectTitle}
                              </td>
                              <td className="table-size">
                                {item.fundingAgencyName}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.dateOfClosure)}
                              </td>
                              <td className="table-size">
                                {item.submissionReport}
                              </td>
                              <td className="table-size">
                                {item.settlementOfAccounts}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        C16. Editorial / Review services by the faculty members
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Organization / Journal
                            </td>
                            <td className="table-size" scope="col">
                              Editorial Board / Reviewer
                            </td>
                            <td className="table-size" scope="col">
                              No. of papers reviewed
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARC16?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.organization}
                              </td>
                              <td className="table-size">
                                {item.editorialBoardReviewer}
                              </td>
                              <td className="table-size">
                                {item.noOfPaperReviewed}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <h3> Publications</h3>
                    <div className="a8-tables">
                      <h6 className="h-bold">
                        D1. Publications in the peer reviewed journals
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col" style={{width : '15%'}}>
                              Authors
                            </td>
                            <td className="table-size" scope="col" style={{width : '5%'}}>
                              Year
                            </td>
                            <td className="table-size" scope="col" style={{width : '18%'}}>
                              Title
                            </td>
                            <td className="table-size" scope="col">
                              Journal
                            </td>
                            <td className="table-size" scope="col" style={{width : '4%'}}>
                              Vol
                            </td>
                            <td className="table-size" scope="col" style={{width : '11%'}}>
                              Issue
                            </td>
                            <td className="table-size" scope="col" style={{width : '9%'}}>
                              Page No.
                            </td>
                            <td className="table-size" scope="col" style={{width : '11%'}}>
                              ISSN # NAAS ID
                            </td>
                            <td className="table-size" scope="col">
                              NAAS rating
                            </td>
                            <td className="table-size" scope="col">
                              SCI Index
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD01?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.authors}</td>
                              <td className="table-size">
                                {item.publicationsYear}
                              </td>
                              <td className="table-size">
                                {item.titleOfPublications}
                              </td>
                              <td className="table-size">{item.journal}</td>
                              <td className="table-size">{item.volume}</td>
                              <td className="table-size">{item.issue}</td>
                              <td className="table-size">{item.pageNo}</td>
                              <td className="table-size">{item.issnnaasid}</td>
                              <td className="table-size">{item.naasRating}</td>
                              <td className="table-size">{item.sciIndex}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">D2. Books/Book Chapter </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Authors
                            </td>
                            <td className="table-size" scope="col" style={{width : '5%'}}>
                              Year
                            </td>
                            <td className="table-size" scope="col">
                              Title
                            </td>
                            <td className="table-size" scope="col">
                              Type
                            </td>
                            <td className="table-size" scope="col">
                              Book Name
                            </td>
                            <td className="table-size" scope="col">
                              Publisher
                            </td>
                            <td className="table-size" scope="col">
                              Page No.
                            </td>
                            <td className="table-size" scope="col">
                              ISBN
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD02?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.authors}</td>
                              <td className="table-size">{item.year}</td>
                              <td className="table-size">
                                {item.titleOfBookChapter}
                              </td>
                              <td className="table-size">{item.bookType}</td>
                              <td className="table-size">{item.bookName}</td>
                              <td className="table-size">{item.publisher}</td>
                              <td className="table-size">{item.pageNo}</td>
                              <td className="table-size">{item.isbn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        D3. Papers published in Conference / Seminars /
                        Symposium
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Authors
                            </td>
                            <td className="table-size" scope="col">
                              Year
                            </td>
                            <td className="table-size" scope="col">
                              Article Title
                            </td>
                            <td className="table-size" scope="col">
                              Conference Title
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              Place
                            </td>
                            <td className="table-size" scope="col">
                              Organizer
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD03?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.authors}</td>
                              <td className="table-size">{item.year}</td>
                              <td className="table-size">
                                {item.articleTitle}
                              </td>
                              <td className="table-size">
                                {item.conferenceTitle}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.conferenceDate)}
                              </td>
                              <td className="table-size">{item.place}</td>
                              <td className="table-size">{item.organizer}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">D4. Popular articles</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Authors
                            </td>
                            <td className="table-size" scope="col">
                              Article Date
                            </td>
                            <td className="table-size" scope="col">
                              Title of the article
                            </td>
                            <td className="table-size" scope="col">
                              Magazine / Newspaper
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD04?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.authors}</td>
                              <td className="table-size">{item.articleDate}</td>
                              <td className="table-size">
                                {item.articleTitle}
                              </td>
                              <td className="table-size">
                                {item.magazineNewspaper}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        D5. Conference / Seminar / Symposium / Training /
                        Workshop attended
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Event Name
                            </td>
                            <td className="table-size" scope="col">
                              No. of days
                            </td>
                            <td className="table-size" scope="col">
                              Date(s)
                            </td>
                            <td className="table-size" scope="col">
                              Online/ offline
                            </td>
                            <td className="table-size" scope="col">
                              Organized by
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD05?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.eventName}</td>
                              <td className="table-size">{item.noOfdays}</td>
                              <td className="table-size">{item.eventdate}</td>
                              <td className="table-size">{item.eventType}</td>
                              <td className="table-size">{item.organizedby}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        D6. Conference / Seminar / Symposium / Training /
                        Workshop organized
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Name of the event
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              Number of participants
                            </td>
                            <td className="table-size" scope="col">
                              Target group
                            </td>
                            <td className="table-size" scope="col">
                              Funding agency
                            </td>
                            <td className="table-size" scope="col">
                              Budget (Rs. in Lakhs)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD06?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.eventName}</td>
                              <td className="table-size">{item.eventdate}</td>
                              <td className="table-size">
                                {item.numberofparticipants}
                              </td>
                              <td className="table-size">{item.targetGroup}</td>
                              <td className="table-size">
                                {item.fundingAgencyName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        D7. Invited / Plenary lecture delivered{" "}
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            {/* <td className="table-size" scope="col">
                            Title of the talk
                          </td> */}
                            <td className="table-size" scope="col">
                              Name of the event
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              Number of participants
                            </td>
                            <td className="table-size" scope="col">
                              Sponsor
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARD07?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              {/* <td className="table-size">{item.titleofthetalk}</td> */}
                              <td className="table-size">{item.eventName}</td>
                              <td className="table-size">
                                {dateFormat(item.eventdate)}
                              </td>
                              <td className="table-size">
                                {item.numberofparticipants}
                              </td>
                              <td className="table-size">{item.sponsor}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <h3>Extension Activities</h3>
                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E1. Trainings / Meetings / Field Days organize
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Meeting
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              M
                            </td>
                            <td className="table-size" scope="col">
                              F
                            </td>
                            <td className="table-size" scope="col">
                              SC/ST
                            </td>
                            <td className="table-size" scope="col">
                              Total
                            </td>
                            <td className="table-size" scope="col">
                              Target group
                            </td>
                            <td className="table-size" scope="col">
                              Funding agency
                            </td>
                            <td className="table-size" scope="col">
                              Budget (Rs. in Lakhs)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE01?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">{item.meetingName}</td>
                              <td className="table-size">
                                {dateFormat(item.meetingDate)}
                              </td>
                              <td className="table-size">{item.noofMale}</td>
                              <td className="table-size">{item.noOfFemale}</td>
                              <td className="table-size">{item.noofSCST}</td>
                              <td className="table-size">{item.total}</td>
                              <td className="table-size">{item.targetGroup}</td>
                              <td className="table-size">
                                {item.fundingAgencyName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E2. OFT / FLD / FS / FFS Programs organized
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Variety/Technology Demonstrated
                            </td>
                            <td className="table-size" scope="col">
                              Team leader / member
                            </td>
                            <td className="table-size" scope="col">
                              Place
                            </td>
                            <td className="table-size" scope="col">
                              No. of trial
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE02?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">
                                {item.programmeType}
                              </td>
                              <td className="table-size">
                                {item.teamleaderRole}
                              </td>
                              <td className="table-size">{item.place}</td>
                              <td className="table-size">{item.nooftrials}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E3. Trainings / Meetings / Field days attended
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Event Name
                            </td>
                            <td className="table-size" scope="col">
                              No. of days
                            </td>
                            <td className="table-size" scope="col">
                              Date(s)
                            </td>
                            <td className="table-size" scope="col">
                              Organized by
                            </td>
                            <td className="table-size" scope="col">
                              Sponsor
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE03?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">{item.eventName}</td>
                              <td className="table-size">{item.noOfdays}</td>
                              <td className="table-size">
                                {dateFormat(item.eventdate)}
                              </td>
                              <td className="table-size">{item.organizedby}</td>
                              <td className="table-size">{item.sponsor}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E4. Diagnostic Field Visit and Farm Advisory made
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                            <td className="table-size" scope="col">
                              Particulars
                            </td>
                            <td className="table-size" scope="col">
                              Number of beneficiaries
                            </td>
                            <td className="table-size" scope="col">
                              Solutions offered
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE04?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">
                                {dateFormat(item.fieldVisitDate)}
                              </td>
                              <td className="table-size">{item.particulars}</td>
                              <td className="table-size">
                                {item.numberofbeneficiaries}
                              </td>
                              <td className="table-size">{item.solutions}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E5. Other Extension Activities undertaken
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col" style={{width : '12%'}}>
                              Name Of The Faculty
                            </td>
                            <td className="table-size" scope="col" style={{width : '6%'}}>
                              Activities
                            </td>
                            <td className="table-size" scope="col">
                              Title of the Programme
                            </td>
                            <td className="table-size" scope="col">
                              Date of recording of the programme
                            </td>
                            <td className="table-size" scope="col">
                              Date of broadcasting of the programme
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE05?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">{item.activities}</td>
                              <td className="table-size">
                                {item.programTitle}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.recordingDate)}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.broadCastingDate)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E6. KAVIADP / ATMA / other extension services
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Responsibility
                            </td>
                            <td className="table-size" scope="col">
                              Activity during this month
                            </td>
                            <td className="table-size" scope="col">
                              Place & Venue
                            </td>
                            <td className="table-size" scope="col">
                              Number of farmers benefitted
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE06?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">
                                {item.responsibility}
                              </td>
                              <td className="table-size">{item.activity}</td>
                              <td className="table-size">{item.place}</td>
                              <td className="table-size">{item.noofFarmer}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <hr></hr> */}
                    {/* <h6 className="h-6">D. Institutional Development</h6> */}

                    <div className="a8-tables">
                      <h6 className="h-bold">E7. FPO linkages established</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Name of the FPO
                            </td>
                            <td className="table-size" scope="col">
                              Address
                            </td>
                            <td className="table-size" scope="col">
                              Mandate of FPO
                            </td>
                            <td className="table-size" scope="col">
                              No. of members in the FPO
                            </td>
                            <td className="table-size" scope="col">
                              Date of signing of MoU
                            </td>
                            <td className="table-size" scope="col">
                              Follow up activities
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE07?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">{item.nameOfFPO}</td>
                              <td className="table-size">{item.address}</td>
                              <td className="table-size">
                                {item.mandateofFPO}
                              </td>
                              <td className="table-size">
                                {item.noofMemberFPO}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.mouDate)}
                              </td>
                              <td className="table-size">{item.fellowUp}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">E8. SHG created / facilitated</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Name of the SHG and address
                            </td>
                            <td className="table-size" scope="col">
                              Mandate of SHG
                            </td>
                            <td className="table-size" scope="col">
                              No. of members in the SHG
                            </td>
                            <td className="table-size" scope="col">
                              Date of signing of SHG
                            </td>
                            <td className="table-size" scope="col">
                              Services extended
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE08?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">{item.nameOfSHG}</td>
                              <td className="table-size">
                                {item.mandateofSHG}
                              </td>
                              <td className="table-size">
                                {item.noofMemberSHG}
                              </td>
                              <td className="table-size">
                                {dateFormat(item.shgDate)}
                              </td>
                              <td className="table-size">
                                {item.servicesExtended}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="a8-tables">
                      <h6 className="h-bold">
                        E9. Policy documents developed (Policy documents /
                        Inventory / Technology Gap assessment / Impact
                        assessment)
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            {/* <td scope="col">S.No</td> */}
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Description of the document
                            </td>
                            <td className="table-size" scope="col">
                              State / National /International
                            </td>
                            <td className="table-size" scope="col">
                              Lead author / Member
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARE09?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">{item.facultyName}</td>
                              <td className="table-size">
                                {item.documentDesc}
                              </td>
                              <td className="table-size">
                                {item.releasedLevel}
                              </td>
                              <td className="table-size">
                                {item.participantType}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <h3>Institutional Development</h3>
                    <div className="b15-tables">
                      <h6 className="h-bold">
                        F1. Production and distribution of Seeds / Seedlings /
                        Bio-inputs
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Station
                            </td>
                            <td className="table-size" scope="col">
                              Crop
                            </td>
                            <td className="table-size" scope="col">
                              Variety
                            </td>
                            <td className="table-size" scope="col">
                              BS/FS/ CS/TL
                            </td>
                            <td className="table-size" scope="col">
                              Quantity produced
                            </td>
                            <td className="table-size" scope="col">
                              Qty distributed
                            </td>
                            <td className="table-size" scope="col">
                              Quantity distributed in this month
                            </td>
                            <td className="table-size" scope="col">
                              Balance
                            </td>
                            <td className="table-size" scope="col">
                              Quantity produced
                            </td>
                            <td className="table-size" scope="col">
                              Quantity distributed
                            </td>
                            <td className="table-size" scope="col">
                              Balance
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF01?.map((item) => (
                            <tr key={item.id}>
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">{item.cropName}</td>
                              <td className="table-size">{item.variety}</td>
                              <td className="table-size">{item.varietyType}</td>
                              <td className="table-size">
                                {item.seedQtyProduced}
                              </td>
                              <td className="table-size">
                                {item.seedQtyDistributed}
                              </td>
                              <td className="table-size">
                                {item.seedQtyDistributedMonth}
                              </td>
                              <td className="table-size">
                                {item.seedQtyBalance}
                              </td>
                              <td className="table-size">
                                {item.bioInputProduced}
                              </td>
                              <td className="table-size">
                                {item.bioInputDistributed}
                              </td>
                              <td className="table-size">
                                {item.bioInputBalance}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">F2. Income generated</h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Source of revenue
                            </td>
                            <td className="table-size" scope="col">
                              Revenue generated up to the last year (Rs.) Upto
                              31.03.2023
                            </td>
                            <td className="table-size" scope="col">
                              Revenue generated last month (Rs.) Upto July 2023
                            </td>
                            <td className="table-size" scope="col">
                              Revenue generated during this month (Rs.)- August
                              2023
                            </td>
                            <td className="table-size" scope="col">
                              Total revenue (Rs)
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF02?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.revenueSource}
                              </td>
                              <td className="table-size">
                                {item.revenueLastYear}
                              </td>
                              <td className="table-size">
                                {item.revenueLastMonth}
                              </td>
                              <td className="table-size">
                                {item.revenueCurMonth}
                              </td>
                              <td className="table-size">
                                {item.revenueTotal}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">
                        F3. Academic / Research Responsibilities by the faculty
                        members
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Responsibility
                            </td>
                            <td className="table-size" scope="col">
                              Activity during the month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF03?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.responsibility}
                              </td>
                              <td className="table-size">{item.activity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">
                        F4. Additional Responsibilities by the faculty members
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the faculty
                            </td>
                            <td className="table-size" scope="col">
                              Responsibility
                            </td>
                            <td className="table-size" scope="col">
                              Activity during the month
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF04?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.responsibility}
                              </td>
                              <td className="table-size">{item.activity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">
                        F5. Details of the important Visitors{" "}
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              College / Department / Station / KVK
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Visitor(s)
                            </td>
                            <td className="table-size" scope="col" style={{width : '8%'}}>
                              Date of visit
                            </td>
                            <td className="table-size" scope="col">
                              Purpose
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF05?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">{item.visitorName}</td>
                              <td className="table-size">
                                {dateFormat(item.visitingDate)}
                              </td>
                              <td className="table-size">{item.purpose}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">
                        F6. Major equipment purchased /infrastructure created
                        (above Rs.5.00 lakhs only)
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the station / KVK/Department / College
                            </td>
                            <td className="table-size" scope="col">
                              Name of the equipment / items
                            </td>
                            <td className="table-size" scope="col">
                              Budget in Lakhs
                            </td>
                            <td className="table-size" scope="col">
                              Source of funding
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF06?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.equipmentName}
                              </td>
                              <td className="table-size">{item.budget}</td>
                              <td className="table-size">
                                {item.sourceofFunding}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="b15-tables">
                      <h6 className="h-bold">
                        F7. Award / Recognition / Fellowships
                      </h6>
                      <table class="table table">
                        <thead style={{ border: "1px solid" }}>
                          <tr>
                            <td className="table-size" scope="col">
                              Name of the Station/KVK / Department/College
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Scientist(s)
                            </td>
                            <td className="table-size" scope="col">
                              Name of the Award / Recognition / Fellowship
                            </td>
                            <td className="table-size" scope="col">
                              Awarded by
                            </td>
                            <td className="table-size" scope="col">
                              Date
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {report.trnIARF07?.map((item) => (
                            <tr key={item.id}>
                              {/* <td scope="row">1</td> */}
                              <td className="table-size">
                                {item.instituteName}
                              </td>
                              <td className="table-size">
                                {item.investigatorName}
                              </td>
                              <td className="table-size">
                                {item.nameoftheAward}
                              </td>
                              <td className="table-size">{item.awardedby}</td>
                              <td className="table-size">
                                {dateFormat(item.awardDate)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportTableAr;
