import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { parseInt } from "lodash";
import { useReactToPrint } from "react-to-print";
import ReportTableLoop from "./ReportTableLoop";
import "./ReportTable.css";
import ComponentLoader from "../../ComponentLoader";
import { dateFormat } from "../../utils/Date";

function ReportTables() {
  const compoRef = useRef();
  const [state, setState] = useState({
    role: "",
    monthName: "",
    reportMonth: "",
    token: "",
    issTruee: "",
    arrMainData: [],
  });
  const [rolee, setRolee] = React.useState("");
  const [ids, setIds] = useState();
  const [ff, setFF] = useState({});

  const [loader, setLoader] = useState(true);

  const [report, setReport] = useState({
    masinvestigator: [],
  });

  useEffect(() => {
    const rolee = localStorage.getItem("role");
    const tok = localStorage.getItem("token");
    const investigators = localStorage.getItem("loginId");
    const Month = localStorage.getItem("Month");
    let reportTableData = localStorage.getItem("reportTableData");
    const objectString = localStorage.getItem("MReport");
    let monName = localStorage.getItem("Mname");
    let reportData = JSON.parse(reportTableData);
    const myObject = JSON.parse(objectString);
    setState((prev) => ({
      ...prev,
      token: tok,
      diId: investigators,
      role: rolee,
      monthName: monName,
      issTruee: rolee,
      myObject: myObject,
    }));
    setIds(ids);
    console.log(reportData);
    setFF(reportData);

    if (tok != null && tok != undefined) {
      if (rolee === "Investigator") {
        inVistigator(tok, reportData, null, null);
      } else {
        handleChooseMonth(tok, reportData, null);
      }
    }
  }, []);

  const inVistigator = async (token, report, month, investor) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MONTHCHOOSE}`,
        report,
        { headers: h }
      );
      console.log(data);
      setLoader(false);

      setState((prev) => ({
        ...prev,
        arrMainData: data,
      }));
    } catch (er) {
      console.log(er);
      setLoader(false);
    }
  };

  const handleChooseMonth = async (token, report, obj) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MONTHCHOOSE}`,
        report,
        { headers: h }
      );
      console.log(data);
      setLoader(false);
      if (data.length > 0) {
        setState((prev) => ({
          ...prev,
          arrMainData: data,
        }));
        if (data && data.TrnMonthlyReport && data.TrnMonthlyReport.RptMonth) {
          localStorage.setItem("RptMonth", data.TrnMonthlyReport.RptMonth);
          localStorage.setItem("MRSId", data.TrnMonthlyReport.id);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // const handlepdf = useReactToPrint({
  //   content: () => compoRef.current,
  //   // documentTitle: "Soil Testing",
  //   // onAfterPrint: () => alert("print success"),
  // });

  console.log(ff.InvestigatorId, ff.InstituteId, rolee);

  return (
    <>
      <ComponentLoader isLoading={loader} />
      {state.role === "Director" &&
      ff.InvestigatorId == 0 &&
      ff.InstituteId == 0 ? (
        <ReportTableLoop />
      ) : (
        <>
          {state.arrMainData?.map((item, index) => {
            let { appModelDTO } = item;
            return (
              <div className="containerss">
                <div className="button-container">
                  <div className="buton" style={{ position: "fixed" }}>
                    <button
                      style={{ border: "solid 1px #fff" }}
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>

                <div
                  className="container-faculty"
                  ref={compoRef}
                  // style={{ width: "100%" }}
                >
                  <div
                    className="top-heading"
                    style={{ textAlign: "center", paddingTop: "60px" }}
                  >
                    <h3>TAMIL NADU AGRICULTURAL UNIVERSITY</h3>
                    {state.issTruee === "Investigator" ? (
                      <h4 className="h-6" style={{ fontSize: "16px" }}>
                        Individual Faculty/Staff
                      </h4>
                    ) : null}
                    <h4 className="h-6" style={{ fontSize: "13px" }}>
                      Monthly Report for the Month of {state.monthName}
                    </h4>
                  </div>

                  {state.issTruee === "Investigator" ? (
                    <>
                      <div>
                        <div>
                          <div>
                            <table className="table tables">
                              <tbody>
                                <tr>
                                  <td scope="row" className="th-row">
                                    Name of the Scientist
                                  </td>
                                  <td
                                    style={{ width: "20px" }}
                                    className="th-row3"
                                  >
                                    {" "}
                                    {
                                      appModelDTO.masinvestigator
                                        ?.investigatorName
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="table-size"
                                    style={{ width: "20px" }}
                                    scope="row"
                                  >
                                    Discipline
                                  </td>
                                  <td className="th-row3">
                                    {" "}
                                    {appModelDTO.masinvestigator?.discipline}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="table-size"
                                    style={{ width: "20px" }}
                                    scope="row"
                                  >
                                    Designation &amp; Address
                                  </td>
                                  <td className="th-row3">
                                    {" "}
                                    {appModelDTO.masinvestigator?.designation}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="table-size"
                                    style={{ width: "20px" }}
                                    scope="row"
                                  >
                                    DOJ in the present office
                                  </td>
                                  <td className="th-row3">
                                    {appModelDTO.masinvestigator?.doj &&
                                      appModelDTO.masinvestigator.doj.substring(
                                        0,
                                        10
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="table-size"
                                    style={{ width: "20px" }}
                                    scope="row"
                                  >
                                    UPF/CPF No.
                                  </td>
                                  <td className="th-row3">
                                    {" "}
                                    {appModelDTO.masinvestigator?.upfcpfNo}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="table-size"
                                    style={{ width: "20px" }}
                                    scope="row"
                                  >
                                    Google Scholar ID
                                  </td>
                                  <td className="th-row3">
                                    {" "}
                                    {
                                      appModelDTO.masinvestigator
                                        ?.googleScholarID
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="table-size"
                                    style={{ width: "20px" }}
                                    scope="row"
                                  >
                                    IRINS ID
                                  </td>
                                  <td className="th-row3">
                                    {" "}
                                    {appModelDTO.masinvestigator?.irinsid}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <h6 className="h-bold">Leave Particulars:</h6>
                  <table className="table table-2">
                    <thead style={{ border: "1px solid" }}>
                      <tr>
                        <td className="table-size" scope="col">
                          Name
                        </td>
                        <td className="table-size" scope="col">
                          Designation
                        </td>
                        <td className="table-size" scope="col">
                          Start Date
                        </td>
                        <td className="table-size" scope="col">
                          End Date
                        </td>

                        <td className="table-size" scope="col">
                          Nature of Leave
                        </td>
                        <td className="table-size" scope="col">
                          Reason
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {appModelDTO.leave?.map((item) => (
                        <tr key={item.id}>
                          <td className="table-size" data-label="Name">
                            {item.investigatorName}
                          </td>
                          <td className="table-size" data-label="Designation">
                            {item.designation}
                          </td>
                          <td className="table-size" data-label="Date">
                            {item.startDate}
                          </td>
                          <td className="table-size" data-label="Date">
                            {item.endDate}
                          </td>

                          <td
                            className="table-size"
                            data-label="Nature of Leave"
                          >
                            {item.levName}
                          </td>
                          <td className="table-size" data-label="Reason">
                            {item.notes}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div>
                    <h6 className="h-bold">Tour/On-duty meetings attended:</h6>
                    <table class="table table-2">
                      <thead style={{ border: " 1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Faculty Name
                          </td>
                          <td className="table-size" scope="col">
                            Start Date
                          </td>
                          <td className="table-size" scope="col">
                            End Date
                          </td>
                          <td className="table-size" scope="col">
                            Duty details
                          </td>
                          <td className="table-size" scope="col" style={{width : '22%'}}>
                            Place
                          </td>
                          <td className="table-size" scope="col">
                            Purpose
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.onDuty?.map((item) => (
                          <tr key={item.id}>
                            <td className="table-size" scope="row">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.fromDate.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {item.toDate.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {item.tourOrDutyName}
                            </td>
                            <td className="table-size">{item.place}</td>
                            <td className="table-size">{item.purpose}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <h6 className="h-bold">TSMS report submission:</h6>
                    <table class="table table-2">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name
                          </td>
                          <td className="table-size" scope="col">
                            Designation
                          </td>
                          <td className="table-size" scope="col">
                            Date of non-submission
                          </td>
                          <td className="table-size" scope="col">
                            Reason
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.tsms?.map((item) => (
                          <tr key={item.id}>
                            <td className="table-size" scope="row">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.designation}</td>
                            <td className="table-size">
                              {item.rptDate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.reason}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a1-tables">
                    <h6>A. Educational Activities</h6>
                    <h6 className="h-bold">
                      A1. Diploma / UG / PG / Ph.D. Courses handled
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No.</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Designation
                          </td>
                          <td className="table-size" scope="col">
                            Course Number
                          </td>
                          <td className="table-size" scope="col">
                            Title
                          </td>
                          <td className="table-size" scope="col">
                            credit hours
                          </td>
                          <td className="table-size" scope="col">
                            Course Type
                          </td>
                          <td className="table-size" scope="col">
                            Course Teacher/Associate
                          </td>
                          <td className="table-size" scope="col" style={{width : '13%'}}>
                            No. of classes conducted (Theory + Practical)
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRA1?.map((item) => (
                          <tr key={item.id} className="A111">
                            <td className="table-size" data-label="Name">
                              {item.investigatorName}
                            </td>
                            <td className="table-size" data-label="Designation">
                              {item.designation}
                            </td>
                            <td className="table-size" data-label="Date">
                              {item.courseNumber}
                            </td>
                            <td
                              className="table-size"
                              data-label="Nature of Leave"
                            >
                              {item.courseName}
                            </td>
                            <td
                              className="table-size"
                              data-label="Nature of Leave"
                            >
                              {item.hours}
                            </td>
                            <td
                              className="table-size"
                              data-label="Nature of Leave"
                            >
                              {item.courseType}
                            </td>
                            <td
                              className="table-size"
                              data-label="Nature of Leave"
                            >
                              {item.courseRole}
                            </td>
                            <td
                              className="table-size"
                              data-label="Nature of Leave"
                            >
                              {item.noOfClassTP}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a2-tables">
                    <h6 className="h-bold">
                      A2. Additional efforts in teaching
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No.</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Course Number
                          </td>
                          <td className="table-size" scope="col">
                            Course Title
                          </td>
                          <td className="table-size" scope="col">
                            Credit hours
                          </td>
                          <td className="table-size" scope="col">
                            No. of slow learners identified
                          </td>
                          <td className="table-size" scope="col">
                            Weaknesses identified
                          </td>
                          <td className="table-size" scope="col" style={{width : '12%'}}>
                            Additional efforts taken to the slow learners
                          </td>
                          <td className="table-size" scope="col">
                            Pass %
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRA2?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.courseNumber}</td>
                            <td className="table-size">{item.courseName}</td>
                            <td className="table-size">{item.hours}</td>
                            <td className="table-size">
                              {item.noofslowlearners}
                            </td>
                            <td className="table-size">{item.noofweakness}</td>
                            <td className="table-size">
                              {item.additionalefforts}
                            </td>
                            <td className="table-size">{item.pass}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a3-tables">
                    <h6 className="h-bold">A3. Guest Lectures Organized:</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No.</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Course Number
                          </td>
                          <td className="table-size" scope="col">
                            Course Title
                          </td>
                          <td className="table-size" scope="col">
                            Credit hours
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Guest Speaker
                          </td>
                          <td className="table-size" scope="col">
                            Affiliation
                          </td>
                          <td className="table-size" scope="col">
                            Title of the lecture
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRA3?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.courseNumber}</td>
                            <td className="table-size">{item.courseName}</td>
                            <td className="table-size">{item.hours}</td>
                            <td className="table-size">{item.guestSpeaker}</td>
                            <td className="table-size">{item.affiliation}</td>
                            <td className="table-size">
                              {item.titleofthelecture}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a4-tables">
                    <h6 className="h-bold">A4. Exposure visits arranged:</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No.</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Course Number
                          </td>
                          <td className="table-size" scope="col">
                            Course Title
                          </td>
                          <td className="table-size" scope="col">
                            Credit hours
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Institute/Industry/Place
                          </td>
                          <td className="table-size" scope="col">
                            No. of students
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRA4?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.courseNumber}</td>
                            <td className="table-size">{item.courseName}</td>
                            <td className="table-size">{item.hours}</td>
                            <td className="table-size">{item.institute}</td>
                            <td className="table-size">{item.noOfStudents}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a5-tables">
                    <h6 className="h-bold">A5. Student Guidance</h6>
                    <table class="table table" style={{width : '100%'}}>
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No.</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td> 
                          <td className="table-size" scope="col" style={{width : '8%'}}>
                            Student ID No
                          </td>
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the Student
                          </td>
                          <td className="table-size" scope="col" style={{width : '6%'}}>
                            Degree
                          </td>
                          <td className="table-size" scope="col" style={{width : '6%'}}>
                            Role
                          </td>
                          <td className="table-size" scope="col" >
                            Thesis title
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Fellowship details
                          </td>
                          <td className="table-size" scope="col" style={{width : '10%'}}>
                            Thesis submission/FVV
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRA5?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.studentId}</td>
                            <td className="table-size">{item.studentName}</td>
                            <td className="table-size">{item.courseType}</td>
                            <td className="table-size">{item.memberRole}</td>
                            <td className="table-size">{item.thesistitle}</td>
                            <td className="table-size">
                              {item.fellowshipdetails}
                            </td>
                            <td className="table-size">
                              {item.thesissubmission.substring(0, 10)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a6-tables">
                    <h6 className="h-bold">A6. Advisory Committee Chairman:</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No.</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Student
                          </td>
                          <td className="table-size" scope="col">
                            No. of meetings held during the month
                          </td>
                          <td className="table-size" scope="col">
                            Institute identified for carrying out of research
                          </td>
                          <td className="table-size" scope="col">
                            Plan for the internship
                          </td>
                          <td className="table-size" scope="col">
                            Progress
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRA6?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.studentName}</td>
                            <td className="table-size">{item.noofmeetings}</td>
                            <td className="table-size">{item.institute}</td>
                            <td className="table-size">
                              {item.internshipPlan}
                            </td>
                            <td className="table-size">{item.progress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a7-tables">
                    <h6 className="h-bold">A7. Teaching related Activities</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Nature of the job
                          </td>
                          {/* <td className="table-size" scope="col">
                       Title of the Course
                     </td>
                     <td className="table-size" scope="col">
                       Number
                     </td> */}
                          <td className="table-size" scope="col">
                            Details Of Work
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.teachingActivitie?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.natureofthejob}
                            </td>
                            {/* <td className="table-size">{item.courseName}</td>
                       <td className="table-size">{item.courseNo}</td> */}
                            <td className="table-size">{item.detailsOfWork}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">A8. Student facilitation:</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Designation
                          </td>
                          <td className="table-size" scope="col">
                            Nature of Coaching Offerd
                          </td>
                          <td className="table-size" scope="col">
                            Number Of Class Offered
                          </td>
                          <td className="table-size" scope="col">
                            Name Of Fellowship Obtained
                          </td>
                          <td className="table-size" scope="col">
                            No Of Students
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.studentFacilitation?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.designation}</td>
                            <td className="table-size">
                              {item.natureofcoaching}
                            </td>
                            <td className="table-size">
                              {item.noofclassesoffered}
                            </td>
                            <td className="table-size">
                              {item.fellowshipName}
                            </td>
                            <td className="table-size">{item.noOfStudent}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* <h6 className="h-6">B. Research Activities</h6> */}
                  <h6 className="h-bold">B1. Research Area:</h6>
                  {/* <h6 className="h-bold">B2. Crop:</h6> */}

                  <div className="b3-tables">
                    <h6 className="h-bold">
                      B3. Target product (Variety/Technology/Tool): (One
                      Scientist One Product)
                    </h6>
                    <table class="table table" style={{width : '100%'}}>
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col" style={{width : '12%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Designation
                          </td>
                          <td className="table-size" scope="col" style={{width : '13%'}}>
                            Dept/Station/KVK
                          </td>
                          <td className="table-size" scope="col" style={{width : '12%'}}>
                            Team members
                          </td>
                          <td className="table-size" scope="col" style={{width : '26%'}}>
                            Name of the product(variety/technology/tool)
                          </td>
                          <td className="table-size" scope="col">
                            Special features of the product
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB3?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.designation}</td>
                            <td className="table-size">{item.instituteName}</td>
                            <td className="table-size">{item.teamMember}</td>
                            <td className="table-size">{item.productName}</td>
                            <td className="table-size">
                              {item.prodcutSpecialFeature}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      B4. Translational Pathway to the product:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name of the product (variety/technology/tool)
                          </td>
                          <td className="table-size" scope="col">
                            Current status
                          </td>
                          <td className="table-size" scope="col">
                            Tentative timeline for release
                          </td>
                          <td className="table-size" scope="col">
                            Progress during the month
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB4?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.productName}</td>
                            <td className="table-size">{item.currentstatus}</td>
                            <td className="table-size">
                              {item.tentativetimeline}
                            </td>
                            <td className="table-size">{item.progress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      B5. New Variety / Technology / Farm Machinery / Software
                      released:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Designation
                          </td>
                          <td className="table-size" scope="col">
                            Dept/Station/KVK
                          </td>
                          <td className="table-size" scope="col">
                            Name of the product released
                          </td>
                          <td className="table-size" scope="col">
                            State /National /International
                          </td>
                          <td className="table-size" scope="col">
                            Special features
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB5?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.designation}</td>
                            <td className="table-size">{item.instituteName}</td>
                            <td className="table-size">{item.productName}</td>
                            <td className="table-size">{item.realeaseType}</td>
                            <td className="table-size">
                              {item.prodcutSpecialFeature}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">B6. Germplasm collection:</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Crop
                          </td>
                          <td className="table-size" scope="col">
                            Germplasm collected during this month
                          </td>
                          <td className="table-size" scope="col">
                            Total Germplasm collected so far
                          </td>
                          <td className="table-size" scope="col">
                            Special features
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB6?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.cropName}</td>
                            <td className="table-size">{item.duringMonth}</td>
                            <td className="table-size">
                              {item.totalGermplasmcollected}
                            </td>
                            <td className="table-size">
                              {item.prodcutSpecialFeature}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      B7. Patents / GIs / NBPGR / PPVFRA registration:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Designation
                          </td>
                          <td className="table-size" scope="col">
                            Dept/Station/ KVK
                          </td>
                          <td className="table-size" scope="col">
                            Description of the product
                          </td>
                          <td className="table-size" scope="col">
                            Patent/GI /NBPGR / PPVFRA details
                          </td>
                          <td className="table-size" scope="col">
                            Remarks
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB7?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.designation}</td>
                            <td className="table-size">{item.instituteName}</td>
                            <td className="table-size">{item.patentType}</td>
                            <td className="table-size">
                              {item.descriptionoftheProduct}
                            </td>
                            <td className="table-size">{item.remarks}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b8-tables">
                    <h6 className="h-bold">
                      B8. Academic / Industrial / FPO linkages established:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Designation
                          </td>
                          <td className="table-size" scope="col">
                            Dept/Station/ KVK
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Organization
                          </td>
                          <td className="table-size" scope="col">
                            National /International/Private / FPO
                          </td>
                          <td className="table-size" scope="col">
                            Purpose
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB8?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.designation}</td>
                            <td className="table-size">{item.instituteName}</td>
                            <td className="table-size">{item.organization}</td>
                            <td className="table-size">
                              {item.fpOlinkageType}
                            </td>
                            <td className="table-size">{item.purpose}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b9-tables">
                    <h6 className="h-bold">
                      B9. MoUs signed/MTA/Linkages developed during this month:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Dept/Station/KVK
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Partnering Organization
                          </td>
                          <td className="table-size" scope="col">
                            Purpose
                          </td>
                          <td className="table-size" scope="col">
                            Date of MoU
                          </td>
                          <td className="table-size" scope="col">
                            Progress during the month
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB9?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.instituteName}</td>
                            <td className="table-size">{item.organization}</td>
                            <td className="table-size">{item.purpose}</td>
                            <td className="table-size">
                              {item.mouDate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.progress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b10-tables">
                    <h6 className="h-bold">
                      B10. Research Projects – On going
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">s.No.</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Title of the Scheme
                          </td>
                          <td className="table-size" scope="col">
                            URP /Externally funded project/AICRP/VCS/RF/NADP
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Role
                          </td>
                          <td className="table-size" scope="col">
                            Budget Rs. In Lakhs
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Start
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            End
                          </td>
                          <td className="table-size" scope="col" style={{width : '28%'}}>
                            Progress during the Month
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB10?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.titleoftheScheme}
                            </td>
                            <td className="table-size">{item.projectType}</td>
                            <td className="table-size">
                              {item.investigatorType}
                            </td>
                            <td className="table-size">{item.budget}</td>
                            <td className="table-size">
                              {item.projectStartTime.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {item.projectEndTime.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.progress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b11-tables">
                    <h6 className="h-bold">
                      B11. MLT/OFT/Action Plan – On going
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">s.No.</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Scientists in-charge
                          </td>
                          <td className="table-size" scope="col">
                            Title
                          </td>
                          <td className="table-size" scope="col" style={{width : '10%'}}>
                            Programme Type
                          </td>

                          <td className="table-size" scope="col">
                            {" "}
                            Centres involved
                          </td>
                          <td className="table-size" scope="col" style={{width : '22%'}}>
                            Progress during the Month
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB11?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.titleoftheActionPlan}
                            </td>
                            <td className="table-size">{item.category}</td>

                            <td className="table-size">
                              {item.centresInvolved}
                            </td>
                            <td className="table-size">{item.progress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b12-tables">
                    <h6 className="h-bold">B12. New Project(s) Proposed:</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col" style={{width : '22%'}}>
                            Title of the Project.
                          </td>
                          <td className="table-size" scope="col" style={{width : '6%'}}>
                            PI/Co-PI
                          </td>
                          <td className="table-size" scope="col">
                            Funding Agency
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Budget in Lakhs
                          </td>
                          <td className="table-size" scope="col">
                            Date of Submission
                          </td>
                          <td className="table-size" scope="col" style={{width : '20%'}}>
                            Status
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB12?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.titleoftheProject}
                            </td>
                            <td className="table-size">
                              {item.investigatorType}
                            </td>
                            <td className="table-size">
                              {item.fundingAgencyName}
                            </td>
                            <td className="table-size">{item.budget}</td>
                            <td className="table-size">
                              {item.dateofSubmission.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b13-tables">
                    <h6 className="h-bold">
                      B13. Closure of completed projects:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Title of the Project.
                          </td>
                          <td className="table-size" scope="col">
                            Funding Agency
                          </td>
                          <td className="table-size" scope="col" style={{width : '12%'}}>
                            Date of closure
                          </td>
                          <td className="table-size" scope="col">
                            Submission of completion report
                          </td>
                          {/* <td className="table-size" scope="col">
                       Settlement of accounts
                     </td> */}
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB13?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.titleoftheProject}
                            </td>
                            <td className="table-size">
                              {item.fundingAgencyName}
                            </td>
                            <td className="table-size">
                              {item.dateofclosure.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {" "}
                              {item.completionReport}
                            </td>
                            {/* <td className="table-size">{item.settlementofaccounts}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b14-tables">
                    <h6 className="h-bold">
                      B14. Publications - Research Activities
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Authors
                          </td>
                          <td className="table-size" scope="col" style={{width : '4%'}}>
                            Year
                          </td>
                          <td className="table-size" scope="col">
                            Title
                          </td>
                          <td className="table-size" scope="col">
                            Journal
                          </td>
                          <td className="table-size" scope="col" style={{width : '5%'}}>
                            Volume
                          </td>
                          <td className="table-size" scope="col" style={{width : '5%'}}>
                            Issue
                          </td>
                          <td className="table-size" scope="col">
                            Page No.
                          </td>
                          <td className="table-size" scope="col" style={{width : '9%'}}>
                            ISSN # NAAS ID
                          </td>
                          <td className="table-size" scope="col">
                            NAAS rating/ Impact factor
                          </td>
                          <td className="table-size" scope="col">
                            SCI Index Yes/No
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB14?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">{item.authors}</td>
                            <td className="table-size">
                              {item.publicationsYear}
                            </td>
                            <td className="table-size">
                              {item.titleOfPublications}
                            </td>
                            <td className="table-size">{item.journal}</td>
                            <td className="table-size">{item.volume}</td>
                            <td className="table-size">{item.issue}</td>
                            <td className="table-size">{item.pageNo}</td>
                            <td className="table-size">{item.issnnaasid}</td>
                            <td className="table-size">{item.naasRating}</td>
                            <td className="table-size">{item.sciIndex}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">B15. Books/Book Chapters</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '6%'}}>
                            Authors
                          </td>
                          <td className="table-size" scope="col" style={{width : '4%'}}>
                            Year
                          </td>
                          <td className="table-size" scope="col">
                            Title
                          </td>
                          <td className="table-size" scope="col">
                            Publisher
                          </td>
                          <td className="table-size" scope="col">
                            Page No.
                          </td>
                          <td className="table-size" scope="col">
                            ISBN
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB15?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">{item.authors}</td>
                            <td className="table-size">{item.year}</td>
                            <td className="table-size">
                              {item.titleOfBookChapter}
                            </td>
                            <td className="table-size">{item.publisher}</td>
                            <td className="table-size">{item.pageNo}</td>
                            <td className="table-size">{item.isbn}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      B16. Conference / Seminar Papers / Articles
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Authors
                          </td>
                          <td className="table-size" scope="col">
                            Year
                          </td>
                          <td className="table-size" scope="col">
                            Title
                          </td>
                          <td className="table-size" scope="col">
                            Conference Title
                          </td>
                          <td className="table-size" scope="col">
                            Date
                          </td>
                          <td className="table-size" scope="col">
                            Place
                          </td>
                          <td className="table-size" scope="col">
                            Organizer
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB16?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">{item.authors}</td>
                            <td className="table-size">{item.year}</td>
                            <td className="table-size">{item.articleTitle}</td>
                            <td className="table-size">
                              {item.conferenceTitle}
                            </td>
                            <td className="table-size">
                              {item.conferenceDate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.place}</td>
                            <td className="table-size">{item.organizer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">B17. Popular articles</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Authors
                          </td>
                          <td className="table-size" scope="col">
                            Year
                          </td>
                          <td className="table-size" scope="col">
                            Title
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Magazine/Newspaper
                          </td>
                          {/* <td className="table-size" scope="col">
                       Artical Title
                     </td> */}
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB17?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">{item.authors}</td>
                            <td className="table-size">{item.year}</td>
                            <td className="table-size">{item.articleTitle}</td>
                            <td className="table-size">
                              {item.magazineNewspaper}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      B18. Conference / Seminar / Symposium / Training /
                      Workshop attended
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Event Name
                          </td>
                          <td className="table-size" scope="col">
                            No. of days
                          </td>
                          <td className="table-size" scope="col" style={{width : '10%'}}>
                            {" "}
                            Date(s)
                          </td>
                          <td className="table-size" scope="col">
                            Organized by
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Sponsor
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB18?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.eventName}</td>
                            <td className="table-size">{item.noOfdays}</td>
                            <td className="table-size">
                              {item.eventdate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.organizedby}</td>
                            <td className="table-size">{item.sponsor}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      B19. Conference / Seminar / Symposium / Training /
                      Workshop organized
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name of the event
                          </td>
                          <td className="table-size" scope="col" style={{width : '9%'}}>
                            Date
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Number of participants
                          </td>
                          <td className="table-size" scope="col">
                            Target group
                          </td>
                          <td className="table-size" scope="col" style={{width : '9%'}}>
                            {" "}
                            Funding agency
                          </td>
                          <td className="table-size" scope="col">
                            Budget Rs. In Lakhs
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB19?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.eventName}</td>
                            <td className="table-size">
                              {item.eventdate.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {item.numberofparticipants}
                            </td>
                            <td className="table-size">{item.targetGroup}</td>
                            <td className="table-size">
                              {item.fundingAgencyName}
                            </td>
                            <td className="table-size">{item.budget}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      B20. Invited / Plenary lecture delivered
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Title of the talk
                          </td>
                          <td className="table-size" scope="col">
                            Name of the event
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            {" "}
                            Date
                          </td>
                          <td className="table-size" scope="col">
                            Number of participants
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Sponsor
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRB20?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.titleofthetalk}
                            </td>
                            <td className="table-size">{item.eventName}</td>
                            <td className="table-size">
                              {item.eventdate.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {item.numberofparticipants}
                            </td>
                            <td className="table-size">{item.sponsor}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <h6 className="h-6">C. Extension Activities</h6>

                  <div className="c1-tables">
                    <h6 className="h-bold">
                      C1. Trainings / Meetings / Field Days organized
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Programme Type
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Date
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            {" "}
                            Number of participants
                          </td>
                          <td className="table-size" scope="col">
                            Target group
                          </td>
                          <td className="table-size" scope="col" style={{width : '12%'}}>
                            {" "}
                            Funding agency
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Budget Rs. In Lakhs
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC1?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.meetingName}</td>
                            <td className="table-size">
                              {item.dom.substring(0, 10)}
                            </td>
                            <td className="table-size">
                              {item.numberofparticipants}
                            </td>
                            <td className="table-size">{item.targetGroup}</td>
                            <td className="table-size">
                              {item.fundingAgencyName}
                            </td>
                            <td className="table-size">{item.budget}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      C2. Trainings / Meetings / Field days attended
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Event Name
                          </td>
                          {/* <td className="table-size" scope="col">
                       No. of days
                     </td> */}
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            {" "}
                            Date(s)
                          </td>
                          <td className="table-size" scope="col">
                            Organized by
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Sponsor
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC2?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.eventName}</td>
                            {/* <td className="table-size">{item.noOfdays}</td> */}
                            <td className="table-size">
                              {item.eventdate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.organizedby}</td>
                            <td className="table-size">{item.sponsor}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      C3. OFT / FLD / FS / FFS Programs organized
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          {/* <td className="table-size" scope="col">
                       Variety/Technology Demonstrated
                     </td> */}
                          <td className="table-size" scope="col">
                            Programme Type
                          </td>
                          <td className="table-size" scope="col" style={{width : '6%'}}>
                            Role
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Place
                          </td>
                          <td className="table-size" scope="col">
                            No. of trials
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC3?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            {/* <td className="table-size">{item.technology}</td> */}
                            <td className="table-size">{item.programmeType}</td>
                            <td className="table-size">
                              {item.teamleaderRole}
                            </td>
                            <td className="table-size">{item.place}</td>
                            <td className="table-size">{item.nooftrials}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      C4. Diagnostic Field Visit and Farm Advisory
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Date
                          </td>
                          <td className="table-size" scope="col">
                            Particulars
                          </td>
                          <td className="table-size" scope="col">
                            {" "}
                            Number of beneficiaries
                          </td>
                          <td className="table-size" scope="col" style={{width : '12%'}}>
                            Solutions offered
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC4?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.fieldVisitDate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.particulars}</td>
                            <td className="table-size">
                              {item.numberofbeneficiaries}
                            </td>
                            <td className="table-size">{item.solutions}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      C5. Other Extension Activities undertaken
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Faculty Name
                          </td>
                          <td className="table-size" scope="col">
                            Activities
                          </td>
                          <td className="table-size" scope="col">
                            Title of the Programme
                          </td>
                          <td className="table-size" scope="col">
                            Date and Time of Program
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC5?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.activities}</td>
                            <td className="table-size">
                              {item.titleoftheProgramme}
                            </td>
                            <td className="table-size">
                              {dateFormat(item.programmeDateTime)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">C6. Editorial / Review services</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Organization / Journal
                          </td>
                          <td className="table-size" scope="col">
                            Role
                          </td>
                          <td className="table-size" scope="col">
                            No. of papers processed /reviewed
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC6?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.organization}</td>
                            <td className="table-size">
                              {item.editorialBoardReviewer}
                            </td>
                            <td className="table-size">
                              {item.nofpapersprocessedreviewed}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      C7. KAVIADP / ATMA / other extension services performed as
                      block level scientist
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name of the program
                          </td>
                          <td className="table-size" scope="col">
                            Activities
                          </td>
                          <td className="table-size" scope="col" style={{width : '7%'}}>
                            Date
                          </td>
                          <td className="table-size" scope="col">
                            Block &amp;Village Name
                          </td>
                          <td className="table-size" scope="col">
                            Number of Beneficiary farmers
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC7?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.nameoftheprogram}
                            </td>
                            <td className="table-size">{item.activities}</td>
                            <td className="table-size">{item.date}</td>
                            <td className="table-size">{item.villageName}</td>
                            <td className="table-size">
                              {item.numberofBeneficiary}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      C8. FPO / SHG created / facilitated
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name of the FPO / SHG created
                          </td>
                          <td className="table-size" scope="col">
                            No. of members
                          </td>
                          <td className="table-size" scope="col">
                            Activities
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC8?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.nameoftheFPO}</td>
                            <td className="table-size">{item.noofmembers}</td>
                            <td className="table-size">{item.activities}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="a8-tables">
                    <h6 className="h-bold">
                      C9. Policy documents developed (Policy documents /
                      Inventory / Technology Gap assessment / Impact assessment)
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Description of the document
                          </td>
                          <td className="table-size" scope="col">
                            State / National / International
                          </td>
                          <td className="table-size" scope="col">
                            Role
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRC9?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.documentDescription}
                            </td>
                            <td className="table-size">{item.realeaseType}</td>
                            <td className="table-size">
                              {item.teamleaderRole}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* <hr></hr> */}
                  {/* <h6 className="h-6">D. Institutional Development</h6> */}

                  <div className="d1-tables">
                    <h6 className="h-bold">
                      D1. Production and distribution of seeds / seedlings /
                      Bio-inputs
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name Of Items
                          </td>
                          <td className="table-size" scope="col">
                            Name Of Crop / BioInput / Produce / Product
                          </td>
                          <td className="table-size" scope="col">
                            Current Month SIH (kg/No.)
                          </td>
                          <td className="table-size" scope="col">
                            Current Month Production (kg/No.)
                          </td>
                          <td className="table-size" scope="col">
                            Current Month Sold
                          </td>
                          <td className="table-size" scope="col">
                            Closing Stock
                          </td>
                          <td className="table-size" scope="col">
                            Income Generated(Rs)
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRD1?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.nameOfItems}</td>
                            <td className="table-size">{item.cropVariety}</td>
                            <td className="table-size">
                              {item.previousBalance}
                            </td>
                            <td className="table-size">
                              {item.quantityProduced}
                            </td>
                            <td className="table-size">{item.quantitySold}</td>
                            <td className="table-size">{item.balance}</td>
                            <td className="table-size">
                              {item.incomeGenerated}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">D2. Additional Responsibilities</h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          {/* <td className="table-size" scope="col">
                       Responsibility
                     </td> */}
                          <td className="table-size" scope="col" style={{width : '11%'}}>
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Responsibility
                          </td>
                          <td className="table-size" scope="col">
                            Activity during the month
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRD2?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            {/* <td className="table-size">{item.responsibility}</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.responsibility}
                            </td>
                            <td className="table-size">{item.activity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      D3. Academic / Research Responsibilities
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          {/* <td className="table-size" scope="col">
                       Responsibility
                     </td> */}
                          <td className="table-size" scope="col">
                            Name of the faculty
                          </td>
                          <td className="table-size" scope="col">
                            Responsibility
                          </td>
                          <td className="table-size" scope="col">
                            Activity during the month
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRD3?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            {/* <td className="table-size">{item.responsibility}</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.responsibility}
                            </td>
                            <td className="table-size">{item.activity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      D4. Visitors handled by the individual scientist
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Name of the Faculty
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Visitor(s)
                          </td>
                          <td className="table-size" scope="col">
                            Date of visit
                          </td>
                          <td className="table-size" scope="col">
                            Purpose
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRD4?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.visitorName}</td>
                            <td className="table-size">
                              {item.visitingDate.substring(0, 10)}
                            </td>
                            <td className="table-size">{item.purpose}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      D5. Equipment / other non-recurring items purchased (above
                      Rs.5.00 lakhs only)
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Faculty Name
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Department
                          </td>
                          <td className="table-size" scope="col">
                            Activity during this month
                          </td>
                          <td className="table-size" scope="col">
                            Name of the equipment/ items
                          </td>
                          <td className="table-size" scope="col">
                            Budget in Lakhs
                          </td>
                          <td className="table-size" scope="col">
                            Source of funding
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRD5?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">{item.departName}</td>
                            <td className="table-size">{item.activity}</td>
                            <td className="table-size">{item.equipmentName}</td>
                            <td className="table-size">{item.budget}</td>
                            <td className="table-size">
                              {item.sourceofFunding}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="b15-tables">
                    <h6 className="h-bold">
                      D6. Award / Recognition / Fellowship:
                    </h6>
                    <table class="table table">
                      <thead style={{ border: "1px solid" }}>
                        <tr>
                          {/* <td scope="col">S.No</td> */}
                          <td className="table-size" scope="col">
                            Faculty Name
                          </td>
                          <td className="table-size" scope="col">
                            Name of the Award / Recognition / Fellowship
                          </td>
                          <td className="table-size" scope="col">
                            Awarded by
                          </td>
                          <td className="table-size" scope="col">
                            Award Date
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {appModelDTO.trnMRD6?.map((item) => (
                          <tr key={item.id}>
                            {/* <td scope="row">1</td> */}
                            <td className="table-size">
                              {item.investigatorName}
                            </td>
                            <td className="table-size">
                              {item.nameoftheAward}
                            </td>
                            <td className="table-size">{item.awardedby}</td>
                            <td className="table-size">
                              {item.awardDate.substring(0, 10)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}

export default ReportTables;
