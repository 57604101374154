import React from "react";

// import './Leave.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import LoopIcon from "@mui/icons-material/Loop";
import { ToastContainer, toast } from "react-toastify";

import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../ComponentLoader";

function A8({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/ManthyReport");
  };

  const nextButton = () => {
    Navigate("/B1");
  };

  const Previousbutton = () => {
    Navigate("/A7");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [loader,setLoader] =useState(false);
  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [Studentguidess, setStudentguidess] = useState([]);

  const [Natureofcoaching, setNatureofcoaching] = useState("");
  const [Noofclassesoffered, setNoofclassesoffered] = useState("");
  const [FellowshipName, setFellowshipName] = useState("");
  const [NoOfStudent, setNoOfStudent] = useState("");
  const location = useLocation()

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);

  const [Mname,setName] = useState("")

  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");
  const [disables,setDisables]=useState(false)
  const [ii,setI] =useState(null)
  const [role, setRole] = useState("");

  const [l, setL] = useState();
  const handleClickOpen = () => {
    setOpen(true);
    navigate("/A8Inside", { state: { TrnMonthlyReport: l } });
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    const objectString = localStorage.getItem('MReport');
    const myObject = JSON.parse(objectString);
    console.log(tok);
   
    if (location.state) {
      console.log(location.state.TrnMonthlyReport);
      setL(location.state.TrnMonthlyReport);
    }
    const role = localStorage.getItem("role");
    
    if (role === "Investigator") {
      if (tok != null && tok != undefined) {
        if(myObject !==null){
          handleDirectorate(tok,myObject.id);
          setI(myObject.id) 
        }
      }
    } else {
      const report = JSON.parse(localStorage.getItem("FacultyAll"));
      if (report) {
        let data = report["studentFacilitation"];
        setStudentguidess(data);
      } else {
        setStudentguidess([]);
      }
    }
    setRole(role);
    let dis = localStorage.getItem("disable");
    if(dis !== null){
      setDisables(true)
    }else{
      setDisables(false)
    }
     let isTrue = localStorage.getItem("Mname")
     if(isTrue !==null){
      setName(isTrue)
     }
    head("Student facilitation");
  }, []);

  const edit = (item) => {
    navigate("/A8Inside", { state: item });
    console.log(item);
    setOpen(true);
    setId(item.id);
  };

  const onclickDelete = async (id) => {
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      // Perform the delete operation
      deleteTransaction(id);
      console.log("Deleted successfully");
      // window.location.reload();
    } else {
      // Cancelled delete operation
      console.log("Delete cancelled");
    }
  };

  const deleteTransaction = async (id) => {
    const d = {
      Id: id,
    };
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.A8DELETE}`,
        d,
        { headers: h }
      );
      handleDirectorate(token,ii)
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectorate = async (token,id) => {
    console.log(token);setLoader(true)
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.A8GETALL}/${id}`,
        { headers: h }
      );
      console.log(data);
      setStudentguidess(data); setLoader(false);
    } catch (error) {
      console.log(error); setLoader(false);
    }
  };

  //student table

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span> (A8) Student facilitation </span>
        </div>
      </div>

      <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>
        <div style={{ marginRight: 20 }}>
          <button className="tables-btn" onClick={nextButton}>
            Next
            <div className="sidebar-icon" />
          </button>
        </div>
      </div>
      <div className="table-container-grid">
        <div className="tables-mains">
          <div className="table-head">
            
            <div>
              <button className="top-button" onClick={handleClickOpen} disabled = {role === "Investigator" && !disables  ? false : true}>
                + Add
              </button>
            </div>
            <div className="Center-Month"><h4 className="h-6">{Mname}</h4></div>
          </div>
        </div>

        <Dialog
          className="dialog"
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="form-control"></div>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell align="right">Name of the faculty</TableCell>
              <TableCell align="right">Designation</TableCell>
              <TableCell align="right">Nature of coaching offered</TableCell>
              <TableCell align="right">No. of classes offered</TableCell>
              <TableCell align="right">Name Of Fellowship</TableCell>
              <TableCell align="right">No. of Students</TableCell>
              <TableCell align="right">Option</TableCell>
            </TableHead>

            <TableBody>
              {Studentguidess?.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.investigatorName}</TableCell>
                  <TableCell align="right">{row.designation}</TableCell>
                  <TableCell align="right">{row.natureofcoaching}</TableCell>
                  <TableCell align="right">{row.noofclassesoffered}</TableCell>
                  <TableCell align="right">{row.fellowshipName}</TableCell>
                  <TableCell align="right">{row.noOfStudent}</TableCell>
                 
                    <TableCell align="right">
                    <div className="edit-delete">
                      <button className="tablebutton" onClick={() => edit(row)} disabled = {role === "Investigator" && !disables  ? false : true}>
                        {<Edit className="sidebars-icon" />}
                      </button>

                      <button
                        className="deleted"
                        style={{ marginLeft: 3 }}
                        onClick={() => onclickDelete(row.id)}
                        disabled = {role === "Investigator" && !disables  ? false : true}
                      >
                        {<DeleteIcon className="sidebars-icon" />}
                      </button>
                      </div>
                    </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

       
      </div>
    </div>
  );
}

export default A8;
