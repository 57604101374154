import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Edit from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";


import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { tableContainerStyle, tableStyle } from "../../../../utils/style";

import { Tsmsvali } from "../../../Validation";
import HomeIcon from "@mui/icons-material/Home";
import ComponentLoader from "../../../../ComponentLoader";

// import './App.css'

function Tsms({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/monthyReport");
  };

  const nextButton = () => {
    Navigate("/A1");
  };

  const Previousbutton = () => {
    Navigate("/transactionOnduty");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const [stateId, setStateId] = useState("");
  // const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [date, setDate] = useState("");
  const [leaveTypeId, setLeaveTypeId] = useState(null);
  const [notes, setNotes] = useState("");

  const [reason, setReason] = useState("");
  const [rptDate, setRptDate] = useState("");
  const [loader,setLoader] =useState(false);
  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [data, setData] = useState([]);

  const [leavess, setLeavess] = useState([]);
  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  console.log(TrnMonthlyReport);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [Mname, setName] = useState("");
const [disables, setDisables] = useState(false);
const [role, setRole] = useState("");

  console.log(date);
  const {
      register,
      handleSubmit,
      formState: { errors, isValid },
      setValue,
      reset,
      watch,
  } = useForm({
      resolver: yupResolver(Tsmsvali),
  });

  const onclickDelete = async (id) => {
    console.log(id);
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      // Perform the delete operation
      deleteTransaction(id);
      console.log("Deleted successfully");
      window.location.reload();
    } else {
      // Cancelled delete operation
      console.log("Delete cancelled");
    }
  };

  const deleteTransaction = async (id) => {
    const d = {
      Id: id,
    };
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.TRANTSMSDELETE}`,
        d,
        { headers: h }
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    const tok = localStorage.getItem("token");
    if (tok != null && tok != undefined) {
      setToken(tok);

      handleLeaveDr(tok);
      console.log(direct, "-----------");
    }
    const role = localStorage.getItem("role");
    setRole(role);
    let dis = localStorage.getItem("disable");
    if(dis !== null){
      setDisables(true)
    }else{
      setDisables(false)
    }

    head("TSMS");
  }, []);

  useEffect(() => {
    const tok = localStorage.getItem("token");
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    const role = localStorage.getItem("role");
    if (role === "Investigator") {
      if (tok != null && tok != undefined) {
        setToken(tok);
        if (myObject !== null) {
          setState(myObject);
      getTransactionTsms(tok, myObject.id);
        }
      }
    } else {
      const report = JSON.parse(localStorage.getItem("FacultyAll"));
      if (report) {
        let data = report["tsms"];
        setDirect(data);
      } else {
        setDirect([]);
      }
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
    }
    const investigator = localStorage.getItem("loginId");
    setInvestigator(investigator);
  }, []);

  //    *********************

  function createData(code, Lname, option) {
    return { code, Lname, option };
  }

  const rows = [
    //     createData('CL', 'Casual Leave', 'Edit'),
    //     createData('SL', 'Sick Leave', 'Edit'),
  ];



  const edit = (item) => {
    console.log(item);
    setOpen(true);
    setValue("datev", item.rptDate);
    setValue("Resonsv", item.reason);
    setId(item.id);
    
  };

  const handleLeaveDr = async (token) => {
    setLoader(true)
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.LEAVES}`, {
        headers: h,
      });
      console.log(data);setLoader(false)
      setLeavess(data);
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  const getTransactionTsms = async (token, id) => {
    setLoader(true)
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.TRANSACTIONTMSM}${"/" + id}`,
        { headers: h }
      );
      console.log(data);
      if (data) {
        setDirect(data);setLoader(false)
      } else {
        toastError(data.errorMsgs);
      }
      //
    } catch (error) {
      console.log(error);setLoader(false)
    }
  };

  /// submit button
  const leaveSubmit = async (data) => {
    // e.preventDefault();
    console.log("sad");
    console.log(data);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      id,
      RptDate:data.datev,
      Reason: data.Resonsv,
      MRSId: state.id,
      investigatorId: parseInt(invis),
      rptMonth: parseInt(assM),
    };
    console.log(d);
    if (id === 0) {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.TRANSACTIONTSMSBUTTON}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully Submitted")
          toastSuccess("Successfully Submitted");
          window.location.reload()
          setId(0);
          setOpen(false);
          // getTransactionTsms(token);
        } else {
          toastError(data.errorMsgs);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.TRANSACTIONTMSMUPDATE}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result === "Success") {
          alert("Successfully updated")
          toastSuccess("Successfully updated");
          window.location.reload()
          setId(0);
          setOpen(false);
          // getTransactionTsms(token)
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const handleClickSubmit = () => {
  //     setOpen(true);
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId(0);
  };

  const dateFormat = (e) => {
    let a = new Date(e.target.value)
      .toISOString()
      .replace(/T.*/, "")
      .split("-")
      .reverse()
      .join("-");
    //console.log(d.toLocaleDateString('de-DE')) // D.M.YYYY
    setRptDate(a);
  };
  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>TSMS</span>
        </div>
      </div>

      <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>
        <div style={{ marginRight: 20 }}>
          <button className="tables-btn" onClick={nextButton}>
            Next
            {/* FD5B14 */}
            <div className="sidebar-icon" />
          </button>
        </div>
      </div>
      <div className="table-container-grid">
        <div className="table-head">
          {/* <div>
                    <button className='top-butto'  onClick={backButton}>

                        <ArrowBackIcon style={{ marginBottom: 3 }} className="sidebar-icon" />
                        Back</button>
                </div> */}
          <div>
            <button className="top-button" onClick={handleClickOpen} disabled = {role === "Investigator" && !disables  ? false : true}>
              + Add
            </button>
          </div>
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            component: "form",
            onSubmit:handleSubmit(leaveSubmit),
          }}
        >
          <DialogTitle id="scroll-dialog-title">
            <div className="dialogueTittle">
              <div className="Dialog-head">
                <h5>TSMS report submission</h5>
              </div>
              <div onClick={handleClose}>
                <CloseIcon
                  style={{ marginBottom: "10", backgroundColor: "black" }}
                  className="dialogue_close_icon"
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="Dialog-box" style={{ padding: 0 }}>
            <div className="manu-form ">
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Date"
                  type="date"
                  variant="outlined"
                  size="small"
                  onChange={dateFormat}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    placeholder: "",
                  }}
                  {...register("datev")}
                  error={errors.datev ? true : false}
                  helperText={errors.datev?.message}
                />
              </div>
            
              <div className="box width-50">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Reason"
                  variant="outlined"
                  size="small"
                  // onChange={(e) => setReason(e.target.value)}

                  {...register("Resonsv")}
                  error={errors.Resonsv ? true : false}
                  helperText={errors.Resonsv?.message}
                />
              </div>
              <div className="box width-50"></div>
            </div>
          </DialogContent>
          <DialogActions className="button">
            {/* <button variant="outlined" onClick={handleClose}style={{ backgroundColor: "#008CBA" }} className="btn2">
              Back
            </button> */}
            <button variant="outlined" type="submit" className="btn1">
            Save
            </button>
          </DialogActions>
        </Dialog>

        {/* ************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              {/* <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Designation</TableCell> */}
              <TableCell align="right">Faculty Name</TableCell>
              <TableCell align="right">Date of non-submission</TableCell>
              <TableCell align="right">Reason</TableCell>
              <TableCell align="right">Option</TableCell>
            </TableHead>

            <TableBody>
              {direct?.map((row) => {
                let a = new Date(row.leaveDate).toLocaleDateString("de-DE");
                console.log(a);
                return (
                  <TableRow
                    key={row.rptDate}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{row.investigatorName}</TableCell>
                
                    <TableCell align="right">
                      {" "}
                      {row.rptDate.substring(0, 10)}
                    </TableCell>
                    <TableCell align="right">{row.reason}</TableCell>

                    <TableCell align="right">
                      <button
                        className="btns"
                        variant="outlined"
                        onClick={() => edit(row)}
                        disabled = {role === "Investigator" && !disables  ? false : true}
                      >
                        {<Edit className="sidebar-icon" />}
                      </button>

                      <button
                        className="deleted"
                        style={{ marginLeft: 3 }}
                        onClick={() => onclickDelete(row.id)}
                        disabled = {role === "Investigator" && !disables  ? false : true}
                      >
                        {<DeleteIcon className="sidebars-icon" />}
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default Tsms;
