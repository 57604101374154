import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { tableContainerStyle, tableStyle } from "../../../utils/style";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Icon,
  Toolbar,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { red, green } from "@mui/material/colors";

import LoopIcon from "@mui/icons-material/Loop";
import { ToastContainer, toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Navigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HomeIcon from "@mui/icons-material/Home";
import Swal from "sweetalert2";
import ComponentLoader from "../../../ComponentLoader";
// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';
const SweetAlert = require("react-bootstrap-sweetalert");

function D6({ head }) {
  const Navigate = useNavigate("");

  const backButton = () => {
    Navigate("/monthyReport");
  };
  useEffect(() => {}, []);
  // const nextButton = () => {
  //   Navigate("/monthyReport")
  // }

  const Previousbutton = () => {
    Navigate("/D5");
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    textTransform: "none",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [stateId, setStateId] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [direct, setDirect] = useState([]);
  const [Info, setInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [Studentguidess, setStudentguidess] = useState([]);

  const [Mname, setName] = useState("");

  const [token, setToken] = useState("");
  const [id, setId] = useState(0);
  const [disables, setDisables] = useState(false);

  const [ii, setI] = useState(null);

  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [leavename, setLeaveName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/D6inside");
  };
  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  useEffect(() => {
    const tok = localStorage.getItem("token");
    setToken(tok);
    console.log(tok);
    const objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    const role = localStorage.getItem("role");
    if (role === "Investigator") {
      if (tok != null && tok != undefined) {
        if (myObject !== null) {
          handleDirectorate(tok, myObject.id);
          setI(myObject.id);
        }
      }
    } else {
      const report = JSON.parse(localStorage.getItem("FacultyAll"));
      if (report) {
        let data = report["trnMRD6"];

        setStudentguidess(data || []);
      } else {
        setStudentguidess([]);
      }
    }
    setRole(role);
    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true);
    } else {
      setDisables(false);
    }
    let isTrue = localStorage.getItem("Mname");
    if (isTrue !== null) {
      setName(isTrue);
    }
    head(" Fellowship");
  }, []);

  const edit = (item) => {
    navigate("/D6inside", { state: item });
    console.log(item);
    setOpen(true);
    setId(item.id);
  };

  const onclickDelete = async (id) => {
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      // Perform the delete operation
      deleteTransaction(id);
      console.log("Deleted successfully");
      // window.location.reload();
    } else {
      // Cancelled delete operation
      console.log("Delete cancelled");
    }
  };

  const deleteTransaction = async (id) => {
    const d = {
      Id: id,
    };
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(`${window.BASE}${window.D6DELETE}`, d, {
        headers: h,
      });
      console.log(data);
      handleDirectorate(token, ii);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectorate = async (token, id) => {
    console.log(token);
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.D6GETALL}${"/" + id}`,
        { headers: h }
      );
      console.log(data);
      setStudentguidess(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const ReportDownloadPageNavigate = () => {
    Navigate("/reportdownloadpage");
  };

  const submitFun = async () => {
    // setDisables(true);
    localStorage.setItem("btnss", true);
    const inst = localStorage.getItem("loginId");
    const Month = localStorage.getItem("Month");
    let objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      MRSId: myObject.id,
      investigatorId: parseInt(inst),
      rptMonth: parseInt(Month),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MONTHSUBMIT}`,
        d,
        { headers: h }
      );
      console.log(data);

      if (data.result == true) {
        // alert("Successfully Submitted");
        Swal.fire({
          title:
            '<span style="color: red;">Warning</span> <br> <h5><span style="color: red;">Once you have submitted, you can not edit further. Ensure filling up of all details before submission</span></h5>',
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Submit",
          cancelButtonText: "Back",
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle the "Save" button click here
            // You can call a function to save the data or perform any other action.
            Swal.fire("Saved!", "Your changes have been saved.", "success");
            setDisables(true);
            localStorage.setItem("disable", true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // Handle the "Back" button click here
            // You can navigate back or perform any other action.
            Swal.fire("Cancelled", "Your changes have not been saved.", "info");
          }
        });
        // setDisables(true);
        // Disable the button after a successful submission
        // localStorage.setItem("disable", true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //student table

  return (
    <div className="main-container-margin">
      <ComponentLoader isLoading={loader} />
      <div className="master-main d-flex">
        <div className="head-icon">
          <Link to="/monthyReport">
            <HomeIcon style={{ color: "white", marginLeft: 38 }} />
          </Link>
        </div>
        <div className="master-main-span">
          <span>(D6). Fellowship</span>
        </div>
      </div>
      <div className="tables-main">
        <div style={{ marginLeft: 20 }}>
          <button className="tables-btn" onClick={Previousbutton}>
            <div className="sidebar-icon" />
            Previous
          </button>
        </div>
        <div style={{ marginRight: 20 }}>
          {/* <button className="tables-btn" onClick={nextButton}> */}
          {/* Next */}
          <div className="sidebar-icon" />
          {/* </button> */}
        </div>
      </div>
      <div className="table-container-grid">
        <div className="tables-mains">
          <div className="table-head">
            {/* <div> */}

            {/* <button className='top-butto' onClick={backButton} >

              <ArrowBackIcon style={{ marginBottom: 3 }} className="sidebar-icon" />
              Back</button> */}

            {/* </div> */}
            {/* <div className='top-button-site'>
            
          </div> */}
            <div>
              <button
                className="top-button"
                onClick={handleClickOpen}
                disabled={role === "Investigator" && !disables ? false : true}
              >
                + Add
              </button>
            </div>
            <div className="Center-Month">
              <h4 className="h-6">{Mname}</h4>
            </div>
          </div>
        </div>

        <Dialog
          className="dialog"
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="form-control"></div>
        </Dialog>

        {/* ************************************************************************* */}

        <TableContainer sx={tableContainerStyle}>
          <Table
            sx={tableStyle}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="headtable">
              <TableCell align="right">Name of the Faculty</TableCell>
              <TableCell align="right">
                Name of the Award /Recognition / Fellowship
              </TableCell>
              <TableCell align="right">Awarded By</TableCell>

              <TableCell align="right">Award Date</TableCell>
              <TableCell align="right">Option</TableCell>
            </TableHead>

            <TableBody>
              {Studentguidess?.map((row) => (
                <TableRow key={row.Natureofcoaching}>
                  <TableCell align="right">{row.investigatorName}</TableCell>
                  <TableCell align="right">{row.nameoftheAward}</TableCell>
                  <TableCell align="right">{row.awardedby}</TableCell>
                  <TableCell align="right"> {row.awardDate}</TableCell>

                  <div className="button-flex">
                    <TableCell align="right">
                      <div className="edit-delete">
                        <button
                          className="tablebutton"
                          onClick={() => edit(row)}
                          disabled={
                            role === "Investigator" && !disables ? false : true
                          }
                        >
                          {<Edit className="sidebars-icon" />}
                        </button>

                        <button
                          className="deleted"
                          style={{ marginLeft: 3 }}
                          onClick={() => onclickDelete(row.id)}
                          disabled={
                            role === "Investigator" && !disables ? false : true
                          }
                        >
                          {<DeleteIcon className="sidebars-icon" />}
                        </button>
                      </div>
                    </TableCell>
                  </div>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="header-btn mt-2 mr-2 d-flex d6-end-btn">
        <button onClick={ReportDownloadPageNavigate}>Preview</button>
        {role === "Investigator" ? (
          <button
            className="mt-2 mr-2"
            id="submitBtn"
            disabled={disables ? true : false}
            onClick={submitFun}
          >
            Submit
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default D6;
